<template>
  <div class="border-b pb-6 mb-10 w-full">
    <div class="bold mb-5">Customer</div>
    <div class="grid grid-rows-2 grid-flow-col gap-3 col-gap-10 items-start" style="grid-auto-columns: max-content;">
      <svg-building :class="style.icon.sm" />
      <svg-customer :class="style.icon.sm" />
      <div class="mr-20 max-w-sm">{{client.name}}</div>
      <div class="mr-20 max-w-sm">{{client.representative}}</div>
      <svg-mail :class="style.icon.sm" />
      <svg-phone :class="style.icon.sm" />
      <div>{{client.email}}</div>
      <div>{{client.phone_number}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        style: this.$store.state.style
      }
    },
    props: {
      client: {type: Object}
    },
  }
</script>
