<template>
  <div style="width: 60rem;" class="p-3">
        <div class="text-xl mb-5">New Quote</div>
        <div class="flex flex-col mb-4">
            <label class="font-bold" :class="style.form.label">OR PICK A TEMPLATE</label>
            <select v-model="formData.price_table" name="carrier" id="" :class="style.form.input" class="bg-gray-100" style="width: 300px">
                <option v-bind:value="[]" selected disabled>Pick a template</option>
                <template v-for="template in templates">
                    <option v-bind:value="template.content.items">{{template.title}}</option>
                </template>
            </select>
            <!-- <input placeholder="Enter a carrier" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" v-model="formData.carrier_id"> -->
        </div>
        <div class="flex justify-between">
            <div>
                <div class="text-black font-bold mb-4">Transit Information</div>
                <div class="flex flex-col mb-4">
                    <label class="font-bold" :class="style.form.label">MODE *</label>
                        <select v-model="formData.mode" name="mode" id="mode" :class="style.form.input" class="bg-gray-100" style="width: 300px">
                            <template v-for="m, index in modes">
                                <option v-bind:value="m">{{m}}</option>
                            </template>
                        </select>
                    <!-- <input placeholder="Enter a mode" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" v-model="formData.mode"> -->
                </div>
                <div class="flex flex-col mb-4">
                    <label class="font-bold" :class="style.form.label">CARRIER *</label>
                    <select v-model="formData.carrier_id" name="carrier" id="" :class="style.form.input" class="bg-gray-100" style="width: 300px">
                        <template v-for="carrier in shipment.status_form.carriers">
                            <option v-bind:value="carrier.id">{{carrier.name}} ({{carrier.carrier_type}})</option>
                        </template>
                        <option value=0>-OR- Add a new carrier</option>
                    </select>
                    <!-- <input placeholder="Enter a carrier" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" v-model="formData.carrier_id"> -->
                </div>
            </div>
            <div style="width: 300px">

                <div class="text-black font-bold mb-4">Route</div>
                <!-- Route Information -->
                <div>
                    <div class="flex justify-between items-center mb-4">
                        <label class="font-bold text-left" :class="style.form.label">ORIGIN *</label>
                        <div class="flex items-center">
                            <input type="number" min="0" :class="style.form.input" class="bg-gray-100 mr-4" style="width: 90px" v-model="formData.origin">
                            <div class="font-bold" :class="style.form.label">days</div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center mb-4">
                        <label class="font-bold text-left" :class="style.form.label">PORT TO PORT *</label>
                        <div class="flex items-center">
                            <input type="number" min="0" :class="style.form.input" class="bg-gray-100 mr-4" style="width: 90px" v-model="formData.port_to_port">
                            <div class="font-bold" :class="style.form.label">days</div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center mb-4">
                        <label class="font-bold text-left" :class="style.form.label">DESTINATION *</label>
                        <div class="flex items-center">
                            <input type="number" min="0" :class="style.form.input" class="bg-gray-100 mr-4" style="width: 90px" v-model="formData.destination">
                            <div class="font-bold" :class="style.form.label">days</div>
                        </div>
                    </div>
                </div>
                <!-- Locations -->
                <!-- <div class="my-4" v-if="formData.routes.length">
                    <div v-for="r, index in formData.routes" class="flex justify-between my-2">
                        <div>
                            <div class="text-base font-bold">{{r.category}}</div>
                            <div class="text-sm">{{r.location}}</div>
                        </div>
                    <div @click="deleteRoute(index)"><svg-trash class="ml-5 w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.50562 1.14667C5.70376 1.14667 2.62183 4.05604 2.62183 7.64452C2.62183 10.4518 6.8356 15.6991 8.6688 17.4729C9.1271 17.9164 9.88042 17.9164 10.3387 17.4729C12.1719 15.6991 16.3857 10.4537 16.3857 7.64452C16.3875 4.05604 13.3075 1.14667 9.50562 1.14667ZM9.50562 10.0269C8.11216 10.0269 6.98218 8.96005 6.98218 7.64452C6.98218 6.32899 8.11216 5.2621 9.50562 5.2621C10.8991 5.2621 12.0291 6.32899 12.0291 7.64452C12.0291 8.96005 10.8991 10.0269 9.50562 10.0269Z" fill="#666666"/>
                        </svg>
                    </div>
                    <div>
                        <select name="location" id="" class="font-semibold flex w-full mb-2" v-model="route.category">
                            <template v-for="location, index in locations">
                                <option class="text-left" v-bind:key="index">{{location}}</option>
                            </template>
                        </select>
                        <input placeholder="Location" type="text" :class="style.form.input" class="bg-gray-100 mb-2" style="width: 235px;" v-model="route.location">
                        <div class="font-bold text-sm text-sky-500 text-right" @click="addRoute">Add</div>
                    </div>
                </div> -->

            </div>
            <div style="width: 300">
                <div class="text-black font-bold mb-4">Shipment Routes</div>
                <div class="my-4" v-if="formData.routes.length">
                    <div v-for="r, index in formData.routes" class="flex justify-between my-2">
                        <div>
                            <div class="text-base font-bold">{{r.category}}</div>
                            <div class="text-sm">{{r.location}}</div>
                        </div>
                    <div @click="deleteRoute(index)"><svg-trash class="ml-5 w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
                    </div>
                </div>

                <div class="flex justify-between">
                    <div>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.50562 1.14667C5.70376 1.14667 2.62183 4.05604 2.62183 7.64452C2.62183 10.4518 6.8356 15.6991 8.6688 17.4729C9.1271 17.9164 9.88042 17.9164 10.3387 17.4729C12.1719 15.6991 16.3857 10.4537 16.3857 7.64452C16.3875 4.05604 13.3075 1.14667 9.50562 1.14667ZM9.50562 10.0269C8.11216 10.0269 6.98218 8.96005 6.98218 7.64452C6.98218 6.32899 8.11216 5.2621 9.50562 5.2621C10.8991 5.2621 12.0291 6.32899 12.0291 7.64452C12.0291 8.96005 10.8991 10.0269 9.50562 10.0269Z" fill="#666666"/>
                        </svg>
                    </div>
                    <div>
                        <select name="location" id="" class="font-semibold flex w-full mb-2" v-model="route.category">
                            <template v-for="location, index in locations">
                                <option class="text-left" v-bind:key="index">{{location}}</option>
                            </template>
                        </select>
                        <input placeholder="Location" type="text" :class="style.form.input" class="bg-gray-100 mb-2" style="width: 235px;" v-model="route.location">
                        <div class="font-bold text-sm text-sky-500 text-right" @click="addRoute">Add</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-4">
        <div class="text-black font-bold mb-4">Quote</div>
        <div class="overflow-y-auto" style="height: 15rem">
            <table class="table-fixed w-full">
                <thead>
                    <tr>
                    <th class="pl-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;"></th>
                    <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 35%;">Description</th>
                    <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Rate</th>
                    <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Quantity</th>
                    <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Unit</th>
                    <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Price</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="s, index in formData.price_table">
                        <tr>
                            <td class="flex-shrink-0">
                                <div @click="removeRow($event, index)" class="cursor-pointer">
                                    <svg t="1628658573462" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2658" width="24" height="24"><path d="M512 128a384 384 0 1 0 0 768 384 384 0 0 0 0-768z m0 713.130667a329.130667 329.130667 0 1 1 0-658.261334 329.130667 329.130667 0 0 1 0 658.261334z m164.565333-356.565334H347.434667a27.434667 27.434667 0 1 0 0 54.869334h329.130666a27.434667 27.434667 0 1 0 0-54.869334z" p-id="2659" fill="#5287B8"></path></svg>
                                </div>
                            </td>
                            <td class="pr-4">
                                <input type="text" :class="style.form.input" class="bg-gray-100 w-full" v-model="formData.price_table[index].item">
                            </td>
                            <td class="pr-4">
                                <input type="number" min="0" :class="style.form.input" class="bg-gray-100 w-full" v-model="formData.price_table[index].price">
                            </td>
                            <td class="pr-4">
                                <input type="number" min="0" :class="style.form.input" class="bg-gray-100 w-full" v-model="formData.price_table[index].quantity">
                            </td>
                            <td>
                                <input type="text" :class="style.form.input" class="bg-gray-100 w-full" v-model="formData.price_table[index].unit">
                            </td>
                            <td class="pr-4">
                                <div class="calculated-cost text-right">{{(s.quantity * s.price) || 0 | currency}}</div>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                        <td class="flex">
                            <div class="font-bold text-sm text-sky-500 text-left mt-2 cursor-pointer mr-2" @click="addField">Add Next</div>
                            <div v-if="formData.price_table.length > 1" class="font-bold text-sm text-sky-500 text-left mt-2 cursor-pointer" @click="removeLastField">Remove Field</div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><div class="font-bold text-right">Total</div></td>
                        <td><div class="font-bold text-right">{{total | currency}}</div></td>
                    </tr>
                </tbody>
            </table>

        </div>
        </div>
        
        <!-- Expiration & Button -->
        <div class="flex justify-end items-end">
            <div v-if="showRequiredAlert" class="text-red-500 duration-300 mr-4">Please fill in all the required information (*)</div>
            <div class="flex flex-col mr-4">
                <label class="font-bold" :class="style.form.label">QUOTE EXPIRATION DATE</label>
                <div :class="style.form.input" style="width: 200px;">
                    <select name="" id="" class="flex w-full" v-model="formData.expiry_date">
                        <template v-for="date, index in expiryDates">
                            <option v-bind:key="index" :value="date">{{date}}</option>
                        </template>
                    </select>
                </div>
            </div>
            <div class="flex-grow-0">
                <button type="button" :class="style.btn.fill" @click="createQuote">Create Quote</button>
            </div>
        </div>

        <form-modal v-if="showCarrier" @toggleModal="toggleCarrier">
            <carrier-form @statusForm="updateCarrier" />
        </form-modal>

  </div>
</template>

<script>
import FormModal from '../../components/form_modal';
import CarrierForm from './carrier_form';

export default {
    data() {
        return {
            style: this.$store.state.style,
            showCarrier: false,
            modes: [
                'Air',
                'Ocean',
                'Truck'
            ],
            templates: [],
            tableRows: [
                        {
                            item: '',
                            price: '',
                            quantity: '',
                            unit: ''
                        },
                    ],
            locations: [
                'Place of pick up',
                'Departure Port',
                'Arrival Port',
                'Place of Delivery'
            ],
            expiryDates: [
                '24 hours',
                '48 hours',
                '7 days',
                '14 days',
                '1 month'
            ],
            formData: {
                mode: '',
                carrier_id: null,
                origin: '',
                port_to_port: '',
                destination: '',
                routes: [],
                price_table: [
                    {
                            item: '',
                            price: '',
                            quantity: '',
                            unit: ''
                    },
                ],
                expiry_date: '24 hours',
                total: null
            },
            route: {
                category: 'Place of pick up',
                location: ''
            },

            tempObj: {
                item: '',
                price: '',
                quantity: '',
                unit: ''
            },

            validations: ['mode', 'carrier_id', 'origin', 'port_to_port', 'destination'],
            showRequiredAlert: false
        }
    },

    mounted() {
        this.getQuoteTemplates()
    },

    props: {
        shipment: { type: Object }
    },

    components: {
        FormModal, CarrierForm
    },

    computed: {
        total() {
            return this.formData.price_table.reduce((total, item) => {
                this.formData.total = total + (item.quantity * item.price)
                return total + (item.quantity * item.price)
            }, 0)
        }
    },

    watch: {
        'formData.carrier_id': function(newValue, oldValue) {
            console.log('carrier_id', this.formData.carrier_id)
        if (this.formData.carrier_id == 0) {
            this.showCarrier = true
        }
      }
    },

    methods: {
        getQuoteTemplates() {
            this.$ax.get('templates')
            .then(res => {
                this.templates = res.data.templates
            })
        },

        createQuote() {
            this.formData.price_table = this.removeEmptyFields(this.formData.price_table)
            const has_data = this.validations.every((k) => this.formData[k] )
            if (has_data) {
                this.$ax.post('quotes', {...this.formData, shipment_id: this.shipment.id})
                .then(res => {
                    console.log(res)
                    this.processData(res)
                })
            } else {
                this.showRequiredAlert = true
            }
        },

        processData(res) {
            if (res.status == 200) {
                this.$router.go()
            }
        },

        addRoute() {
            if (!this.route.location) {
                alert('Please fill in an actual location')
                return
            }
            this.formData.routes.push({...this.route})
        },
        
        deleteRoute(index) {
            this.formData.routes.splice(index,1)
        },

        addField() {
            this.formData.price_table.push({...this.tempObj})
        },

        removeLastField() {
            this.formData.price_table.pop()
        },

        removeRow(e, i) {
            this.formData.price_table.splice(i, 1)
        },

        removeEmptyFields(arr) {
            if (arr.length > 1) {
                console.log(arr)
                return arr.filter(e => e.quantity && e.price && e.item)
            }
            return arr
        },

        toggleCarrier() {
            this.showCarrier = !this.showCarrier
        },

        updateCarrier(action, value) {
            console.log(action, value)
            if (action == 'close form') {
            this.showModal = false
            } else if (action == 'save') {
            this.showModal = false
            this.shipment.status_form.carriers.push(value)
            this.carrierId = value.id
            }
        },

        closeForm() {
            this.$emit('statusForm', 'close form')
        },

    }

}
</script>

<style scoped>
select {
    padding-left: 0;
    text-align-last: left;
    text-align: left;
    -ms-text-align-last: left;
    -moz-text-align-last: left;
    text-indent: -1px;
}

option:focus {
   outline: 0;
 }

</style>