<template>
  <div class="mb-8 w-full" style="margin-top: -0.5rem;">
    <div class="mb-3 flex justify-between">
      <div></div>
      <!-- <div @click="closeForm" class="cursor-pointer">x</div> -->
    </div>

    <div class="bg-gray-200 border pb-3 pt-2 px-3 text-sm w-full">

      <form v-on:submit.prevent="save">
<!-- v-if="!shipment.shipping_statuses.length" -->
        <template v-if="!shipment.shipping_statuses.length||showEditFrom">
          <div class="flex flex-col mb-3">
            <label :class="style.form.label">FROM</label>
            <input v-model="form.origin_city" type="text" name="origin_city" :class="style.form.input" :placeholder="placeholders.city">
          </div>
          <div class="flex flex-col mb-3">
            <label :class="style.form.label">DEPARTURE LOCATION</label>
            <input v-model="form.origin_location_title" type="text" name="origin_location_title" :class="style.form.input" :placeholder="placeholders.locationTitle">
          </div>
          <!-- <div class="flex flex-col mb-3">
            <label :class="style.form.label">LOCATION DETAILS</label>
            <input v-model="form.origin_location_detail" type="text" name="origin_location_detail" :class="style.form.input" :placeholder="placeholders.locationDetail">
          </div> -->
        </template>

        <div class="flex" v-else>
          <label :class="style.form.label">FROM</label>
          <div class="flex items-start justify-between w-full">
            <div class="flex flex-col mb-3 ml-4">
              <div class="text-base">{{form.origin_city}}</div>
              <div class="text-sm text-gray-500">{{form.origin_location_title}}</div>
              <input v-model="form.origin_city" type="text" name="origin_city" :class="style.form.input" class="hidden" :placeholder="placeholders.city">
            </div>
            <div class="w-5 h-5" @click="toggleEditFrom">
              <svg-edit class="w-full h-full cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" />
            </div>
          </div>
        </div>


        <div class="flex flex-col mb-3">
          <label :class="style.form.label">TO</label>
          <input v-model="form.destination_city" type="text" name="destination_city" :class="style.form.input" :placeholder="placeholders.city">
        </div>
        <div class="flex flex-col mb-3">
          <label :class="style.form.label">ARRIVAL LOCATION</label>
          <input v-model="form.destination_location_title" type="text" name="destination_location_title" :class="style.form.input" :placeholder="placeholders.locationTitle">
        </div>
        <!-- <div class="flex flex-col mb-3">
          <label :class="style.form.label">LOCATION DETAILS</label>
          <input v-model="form.destination_location_detail" type="text" name="destination_location_detail" :class="style.form.input" :placeholder="placeholders.locationDetail">
        </div> -->
<!-- v-if="!shipment.shipping_statuses.length"  -->
        <div class="flex flex-col mb-3">
          <label :class="style.form.label">ETD DATE</label>
          <input v-model="form.etd_date" type="date" name="etd_date" :class="style.form.input">
        </div>
        <div class="flex flex-col mb-3">
          <label :class="style.form.label">ATD DATE</label>
          <input v-model="form.atd_date" type="date" name="atd_date" :class="style.form.input">
        </div>
        <div class="flex flex-col mb-3">
          <label :class="style.form.label">ETA DATE</label>
          <input v-model="form.eta_date" type="date" name="eta_date" :class="style.form.input">
        </div>
        <div class="flex flex-col mb-3">
          <label :class="style.form.label">CARRIER</label>
          <!-- <input v-model="form.carrier" type="text" name="carrier" :class="style.form.input"> -->
          <select v-model="carrierId" name="carrier" id="" :class="style.form.input">
            <template v-for="carrier in shipment.status_form.carriers">
              <option v-bind:value="carrier.id">{{carrier.name}} ({{carrier.carrier_type}})</option>
            </template>
            <option value=0>-OR- Add a new carrier</option>
          </select>
        </div>

        <div v-if="!isNew&&$can('manage', 'Shipment')" class="flex flex-col mb-3">
          <label :class="style.form.label">IS THIS JOURNEY COMPLETED?</label>
          <div class="flex items-center">
            <input v-model="form.is_completed" value=true type="radio" :class="style.form.input">
            <label :class="style.form.label" class="ml-2" for="">Yes</label>

            <input v-model="form.is_completed" value=false type="radio" :class="style.form.input" class="ml-3">
            <label :class="style.form.label" class="ml-2" for="">No</label>
          </div>
        </div>

        <!-- <div class="flex flex-col mb-3">
          <label :class="style.form.label">IS THIS THE FINAL DESTINATION?</label>
          <div class="flex items-center">
            <input v-model="form.is_final_destination" value=true type="radio" name="is_final_destination" :class="style.form.input">
            <label :class="style.form.label" class="ml-2" for="">Yes</label>
            <input v-model="form.is_final_destination" value=false type="radio" name="is_final_destination" :class="style.form.input" class="ml-3">
            <label :class="style.form.label" class="ml-2" for="">No</label>
          </div>
        </div> -->

        <!-- <div class="flex justify-between mb-3">
          <div class="flex flex-col w-1/2 pr-1">
            <label for="date-arrive" :class="style.form.label">CARRIER</label>
            <input type="text" id="date-arrive" name="date_arrive" :class="style.form.input">
          </div>
          <div class="flex flex-col w-1/2 pl-1">
            <label for="date-arrive" :class="style.form.label">VESSEL</label>
            <input type="text" id="date-arrive" name="date_arrive" :class="style.form.input">
          </div>
        </div> -->

        <div class="flex items-center">
          <button type="button" class="w-full mt-3" @click="closeForm" :class="style.btn.empty">Cancel</button>
          <button type="submit" class="w-full mt-3" :class="style.btn.fillSm">Save</button>
        </div>
        <div v-if="errorMessage" class="text-red-700 text-sm mt-2">{{errorMessage}}</div>

      </form>
    </div>

    <form-modal v-if="showModal" @toggleModal="toggleModal">
      <carrier-form @statusForm="updateCarrier" />
    </form-modal>

  </div>
</template>

<script>
  import { ShipmentEventBus } from '../helpers/shipment-event-bus.js'
  import FormModal from '../../components/form_modal';
  import CarrierForm from './carrier_form';

  export default {
    data() {
      return {
        style: this.$store.state.style,
        showEditFrom: false,
        validations: ['destination_city', 'eta_date'],
        errorMessage: null,
        placeholders: {
          city: 'City name..',
          locationTitle: 'Port / Warehouse name / etc..',
          locationDetail: 'Address or leave blank..',
        },
        carrier: {},
        form: {
          origin_city: null,
          origin_location_title: null,
          // origin_location_detail: null,
          destination_city: null,
          destination_location_title: null,
          // destination_location_detail: null,
          etd_date: null,
          eta_date: null,
          carrier_id: null,
          // is_final_destination: false,
          is_completed: false,
          // carriers: [],
        },
        isNewCarrier: false,
        showModal: false,
        carrierId: null,
      }
    },
    props: {
      shipment: { type: Object },
      isNew: { type: Boolean },
      status: { type: Object },
    },
    components: {
      FormModal, CarrierForm
    },
    watch: {
      carrierId(newValue, oldValue) {
        console.log('carrier_id', this.carrierId)
        if (this.carrierId == 0) {
          this.showModal = true
        }
      }
    },
    mounted() {
      console.log('inside status form',this.shipment)
      console.log('status', this.status)
      if (this.isNew) {
        let s = this.shipment.status_form
        const form = this.form
        form.origin_city = s.last_status.city
        form.origin_location_title = s.last_status.location_title
        // form.origin_location_detail = s.last_status.location_detail
        form.etd_date = s.last_status.date
      } else {
        let s = this.status
        const form = this.form
        form.origin_city = s.origin_city
        form.origin_location_title = s.origin_location_title
        form.destination_city = s.destination_city
        form.destination_location_title = s.destination_location_title
        form.etd_date = this.$bc.formatDate(new Date(s.etd_date))
        if (form.atd_date) form.atd_date = this.$bc.formatDate(new Date(s.atd_date))
        form.eta_date = this.$bc.formatDate(new Date(s.eta_date))
        form.is_completed = s.is_completed
        form.carrier_id = s.carrier.id
        this.carrierId = s.carrier.id
      }
    },

    methods: {
      toggleEditFrom() {
        this.showEditFrom = true
      },
      closeForm() {
        this.$emit('statusForm', 'close form')
      },
      updateCarrier(action, value) {
        console.log(action, value)
        if (action == 'close form') {
          this.showModal = false
        } else if (action == 'save') {
          this.showModal = false
          this.shipment.status_form.carriers.push(value)
          this.carrierId = value.id
        }
      },
      toggleModal(showModal) {
        this.showModal = showModal
      },
      save() {
        this.form.carrier_id = this.carrierId
        console.log(this.form)
        if (this.validations.every(v=>this.form[v])) {
          if (this.isNew) {
            this.$ax.post(`shipments/${this.shipment.id}/shipping_statuses`, {shipping_status: this.form})
              .then(res => {
                console.log(res)
                // this.$emit('statusForm', 'append status', res.data.shipping_status)
                this.$emit('statusForm', 'do nothing')
                ShipmentEventBus.$emit('reloadData')
              })
          } else {
            this.$ax.put(`shipments/${this.shipment.id}/shipping_statuses/${this.status.id}`, {shipping_status: this.form})
              .then(res => {
                console.log(res)
                this.$emit('statusForm', 'replace status', res.data.shipping_statuses)
                // why this emit not working??
              })
          }
        } else {
          this.errorMessage = 'Please input the city name, ETA, and carrier to continue'
        }

      },
    },
  }
</script>
