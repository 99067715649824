<template>
  <div v-if="dataReady" class="h-screen flex">
  <!-- <div class="bg-gray-100 p-10 min-h-full"> -->
    <div class="bg-gray-100 p-10 w-2/3 overflow-y-scroll h-screen">
      <page-title :defaultStyle="false">
        <div class="text-gray-500 font-bold text-base">{{shipment.shipment_no}}</div>
        <div class="text-gray-500 text-base">HBL {{shipment.hbl}}</div>
        <!-- <div class="text-gray-900 font-bold truncate" style="max-width: 80%;">{{shipment.pos.join("/") || ''}}</div> -->
      </page-title>

      <customer-details v-if="$can('manage', 'Shipment')" :client="shipment.client" />
      <shipment-details :shipment="shipment" />

      <activities @updateValue="updateValue" :inquiries="messages" :documents="documents" :shipment="shipment" :quotes="quotes" :packages="parcels" />
    </div>

    <right-box @updateValue="updateValue" :statuses="shipment.shipping_statuses" :shipment="shipment" @reloadData="getData" class="bg-white p-10 w-1/3 overflow-y-scroll h-screen" />
  </div>
</template>

<script>
  import { ShipmentEventBus } from './helpers/shipment-event-bus.js'
  import RightBox from './components/right_box';
  import CustomerDetails from './components/customer_details';
  import ShipmentDetails from './components/details';
  import Activities from './components/activities';

  export default {
    data() {
      return {
        dataReady: false,
        shipment: {},
        messages: [],
        documents: [],
        quotes: [],
        parcels: []
      }
    },

    components: {
      RightBox, ShipmentDetails, Activities, CustomerDetails
    },

    mounted() {
      ShipmentEventBus.$on('completeStatus', () => this.getData() )
      ShipmentEventBus.$on('reloadData', () => this.getData() )
      this.getData()
    },

    methods: {
      getData() {
        const slug = this.$route.params.slug;
        this.$ax.get('shipments/' + slug)
          .then(res => {
            // console.log(res)
            this.shipment = res.data.shipment
            this.messages = res.data.messages
            this.documents = res.data.documents
            this.quotes = res.data.quotes
            this.parcels = res.data.parcels
            this.dataReady = true
            // console.log('this.shipment', this.shipment, this.quotes)
          })
      },
      // deleteDocRefresh(value) {
      //   console.log('on show.vue  value', value)
      //   this.documents.splice(value, 1)
      // },
      updateValue(action, value) {
        console.log('on show.vue updateValue', action, value)
        if (action == 'remove document') {
          this.documents.splice(value, 1)
        } else if (action == 'append message') {
          // this.messages.push(value)
          this.messages.unshift(value)
        } else if (action == 'append shipping status') {
          this.shipment.shipping_statuses.push(value)
        } else if (action == 'replace shipping status') {
          let s = this.shipment
          s["shipping_statuses"] = value
          this.shipment = s
        } else if (action == 'remove shipping status') {
          let s = this.shipment
          s.shipping_statuses.splice(value, 1)
          this.shipment = s
        } else if (action == 'append document') {
          let d = this.documents.concat(value)
          this.documents = d
        }
      },
    },
  }
</script>

<style>

</style>
