<template>
  <div class="bg-sky-500 flex flex-col items-center justify-between flex-shrink-0">
    <div class="flex flex-col">
      <div @click="goHome" class="cursor-pointer">
        <!-- <img :src="logo" alt="logo" class="w-20 object-contain"> -->
        <img src="~/images/navbar/logo.svg" alt="logo" class="w-12 object-contain">
      </div>
      <!-- <div class="mt-2 text-center bg-blue-500 text-white p-1 rounded-md" v-if="user&&user.role=='staff'">Admin</div> -->
    </div>
    <div class="text-center">
      <div class="my-16">
        <router-link to="/dashboard/shipments" class="tab-link">
          <svg-ship class="w-8 h-8 mx-auto mb-3 duration-300 text-sky-100 fill-current" />
          <p class="text-xs duration-300 text-sky-100 tab-text">Shipments</p>
        </router-link>
      </div>
      <div class="my-16" v-if="clientRoles.includes(currentUser.role)">
        <router-link :to="`/dashboard/customers/${$store.state.client.slug}`" class="tab-link">
          <svg-customer class="w-8 h-8 mx-auto mb-3 duration-300 text-sky-100 fill-current" />
          <p class="text-xs duration-300 text-sky-100 tab-text">My Company</p>
        </router-link>
      </div>
      <!-- <div class="my-16" v-can.manage.User> -->
      <div class="my-16" v-if="$can('manage', 'Client')">
        <router-link to="/dashboard/customers" class="tab-link">
          <svg-customer class="w-8 h-8 mx-auto mb-3 duration-300 text-sky-100 fill-current" />
          <p class="text-xs duration-300 text-sky-100 tab-text">Companies</p>
        </router-link>
      </div>
      <!-- <div class="my-16" v-can.manage.Client> -->
      <div class="my-16">
        <router-link to="/dashboard/quotes" class="tab-link">
          <svg-quote class="w-8 h-8 mx-auto mb-3 duration-300 text-sky-100 fill-current" />
          <p class="text-xs duration-300 text-sky-100 tab-text">Quotes</p>
        </router-link>
      </div>
    </div>
      <div class="relative" v-if="$can('manage', 'users')">
        <router-link to="/dashboard/staff" class="tab-link">
          <svg-staff class="w-8 h-8 mx-auto mb-3 duration-300 text-sky-100 fill-current" />
          <p class="text-xs duration-300 text-sky-100 tab-text">Staff</p>
        </router-link>
      </div>
      <div></div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import VueCanCan from 'vue-cancan';

export default {
    data() {
      return {
        currentUser: '',
        tabCur: 0,
        clientRoles: ['client', 'representative'],
        tabs: [
          { name: 'shipments', label: 'Shipments' },
          { name: 'customers', label: 'Customers' },
        ],
      }
    },

    mounted() {
      this.$store.state.user ? this.getCurrentUser() : this.$root.$on('user-loaded', this.getCurrentUser)
    },

    computed: {
      ...mapState(['logo', 'user'])
    },

    methods: {
      goHome() {
        window.location = this.$store.getters.rootPath
      },

      getCurrentUser() {
        this.currentUser = this.$store.state.user
      },

      logout() {
        const path = this.$store.state.path[this.$store.state.env]
        this.$ax.delete('users/sign_out')
          .then(res => {
            // console.log('signout res', res);
            if (res.status == 200) {
              this.$router.push('/users/sign_in')
            }
          });
      }
    }
  }
</script>

<style>
  .tab-link:hover .tab-text {
    color: #fff;
  }

  .tab-link:hover svg {
    color: #fff;
  }

  .router-link-active > p {
    color: #fff;
  }

  .router-link-active > svg {
    fill: #fff;
  }

  .hover-trigger .hover-target {
    transform: translateY(-100%);
    display: none;
  }

  .hover-trigger:hover .hover-target {
    display: block;
  }
</style>
