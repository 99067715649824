<template>
  <div class="w-screen h-screen fixed inset-0 z-50 flex justify-center items-center">
    <div class="absolute w-full h-full bg-gray-900 opacity-25" @click="hideModal"></div>
    <div class="p-8 bg-white z-10 relative duration-300">
      <div class="absolute text-xs top-0 right-0 mt-2 mr-2 px-2 py-1 border border-gray-400 text-gray-500 hover:border-red-700 hover:text-red-700 cursor-pointer duration-300"
        @click="hideModal">Close</div>
      <slot />

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        style: this.$store.state.style,

      }
    },

    props: {
      page: { type: String }
    },

    methods: {
      hideModal() {
        this.$emit('toggleModal', false)
      }
    }
  }
</script>
