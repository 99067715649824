<template>
  <div class="relative md:flex md:justify-between bg-white py-10">
      <div>
        <div class="text-black text-lg font-bold mb-4">Origin</div>
        <div class="flex flex-col mb-4">
            <label class="font-bold" :class="style.form.label">LOADING PORT *</label>
            <input placeholder="Enter a city" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="loading_port" @change="addToFormData" >
        </div>
        <div class="flex flex-col mb-4">
            <label class="font-bold" :class="style.form.label">ORIGIN LOCATION *</label>
            <!-- <input placeholder="Enter a city" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="origin_location" @change="addToFormData"> -->
            <textarea id="" cols="30" rows="2" placeholder="Detailled Origin Location" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="origin_location" @change="addToFormData"></textarea>
        </div>
      </div>
      <div>
        <div class="text-black text-lg font-bold mb-4">Destination</div>
        <div class="flex flex-col mb-4">
            <label class="font-bold" :class="style.form.label">FINAL DESTINATION *</label>
            <input placeholder="Enter a city" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="final_destination" @change="addToFormData">
        </div>
        <div class="flex flex-col mb-4">
            <label class="font-bold" :class="style.form.label">DESTINATION LOCATION *</label>
            <!-- <input placeholder="Enter a city" type="text" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="destination_location" @change="addToFormData"> -->
            <textarea id="" cols="30" rows="2" placeholder="Detailled Destination" :class="style.form.input" class="bg-gray-100" style="width: 300px" name="destination_location" @change="addToFormData"></textarea>
        </div>
      </div>

  </div>
</template>

<script>
export default {
    data() {
        return {
            style: this.$store.state.style,
            formData: {}
        }
    },

    methods: {
        addToFormData(e) {
            const { name, value } = e.target
            this.formData[name] = value
            this.$emit('destination', this.formData)
        }
    }

}
</script>

<style scoped>

</style>