<template>
  <div class="w-full pb-5 mb-10 text-gray-600 text-xl" :class="hideBorder ? '' : 'border-b'">
    <div v-if="defaultStyle" class="flex justify-between items-center">
      <div class="flex">
        <h3 class="text-2xl mr-10">{{title}}</h3>
        <button v-if="btnText" @click="toggleModal(true)" :class="style.btn.ghost">{{btnText}}</button>
        <!-- <button @click="toggleModal(true)" :class="style.sky">{{btnText}}</button> -->
      </div>
      <div>
        <user-box />
      </div>
    </div>
    <slot v-else/>

    <form-modal v-if="showModal" @toggleModal="toggleModal">
      <shipment-create v-if="form=='shipmentCreate'" />
      <shipment-edit v-if="form=='shipmentEdit'" />
      <staff-create v-if="form=='staffCreate'" />
      <staff-edit v-if="form=='staffEdit'" />
      <customer-staff-create v-if="form=='customerStaffCreate'" />

      <customer-create v-if="form=='customerCreate'" />
    </form-modal>

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import ShipmentCreate from '../shipments/create'
  import ShipmentEdit from '../shipments/edit'
  import CustomerCreate from '../customers/create'
  import CustomerStaffCreate from '../customers/components/staff_create'
  import StaffEdit from '../staff/edit'
  import StaffCreate from '../staff/create'
  import FormModal from './form_modal'
  import UserBox from './user_box'
  import QuoteCreate from '../quotes/create'

  export default {
    data() {
      return {
        showModal: false,
        form: this.btn,
        shipmentCustomer: null,
      }
    },

    props: {
      defaultStyle: { type: Boolean, default: true },
      title: { type: String },
      btnText: { type: String },
      btn: { type: String },
      hideBorder: {type: Boolean}
    },

    computed: {
      ...mapState(['user', 'style'])
    },

    components: {
      FormModal, 
      ShipmentCreate, 
      ShipmentEdit, 
      CustomerCreate, 
      UserBox, 
      StaffCreate, 
      StaffEdit, 
      CustomerStaffCreate,
      QuoteCreate
    },

    methods: {
      initPageTitle() {
        this.form = this.btn
      },

      toggleModal(showModal) {
        if (this.form == 'quoteCreate') this.$router.push('/dashboard/quotes/new')
        this.showModal = showModal
        if (!showModal) {
          this.initPageTitle()
        }
      },

      loadShipmentCreate(e) {
        this.form = 'shipmentCreate'
        this.shipmentCustomer = e
      }
    }
  }
</script>
