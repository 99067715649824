<template>
  <div class="py-3 px-6 rounded-full bg-white" style="width: 30rem;">
    <form class="flex justify-between" onSubmit="return false;">
      <div v-if="searchQueryText"
        class="bg-gray-500 text-white px-5 py-1 rounded-full flex items-center"
        @click="clearSearch">
        <div>{{searchQuery}}</div>
        <svg-cross class="h-3 w-3 ml-3" />
      </div>
      <input v-else type="text" v-model="searchQuery"
        class="w-full text-sm text-gray-800 placeholder-gray-400 duration-300 outline-none"
        :placeholder="placeholder" @keyup.enter="onClickSearch">
      <button type="button" class="w-8 h-8 ml-3 cursor-pointer" @click="onClickSearch">
        <svg-search class="fill-current text-gray-400 hover:text-gray-800 duration-300" />
      </button>
    </form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        searchQuery: null,
        searchQueryText: null
      }
    },

    props: {
      placeholder: { type: String }
    },

    methods: {
      clearSearch() {
        this.searchQuery = null
        this.searchQueryText = null
        this.$emit('search')
      },

      onClickSearch() {
        console.log('searching')
        this.searchQueryText = this.searchQuery
        this.$emit('search', this.searchQuery)
        // this.searchQuery = null
      }
    }
  }
</script>

<style>
  button:focus {outline:0;}
</style>
