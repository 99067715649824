<template>
  <div>
    <div class="flex justify-start items-center mb-5" v-if="$can('manage', 'Shipment')">
      <!-- <button class="mr-5" :class="style.btn.ghostSm">Download all</button> -->
      <input id="uploadButton" ref="file" type="file" style="display:none" @change="uploadFile($event)" multiple>
      <button @click="triggerUpload"
        :class="[isUploading?style.btn.disabledSm:style.btn.ghostSm]"
        :disabled="isUploading">{{isUploading?'Uploading ('+uploadProgress+')':'Upload'}}</button>
    </div>

    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 60%;">NAME</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 30%;">UPLOADED AT</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 10%;"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="doc, index in documents">
          <tr class="bg-white text-sm">
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>
                <div class="text-sm">
                  <div v-if="editingDoc&&editingDoc.id==doc.id" class="flex items-center">
                    <input type="text" v-model="editingDoc.title" @keyup.enter="saveFile" class="mr-5 w-full" :class="style.form.input">
                    <button class="cursor-pointer text-xs text-blue-500 hover:text-blue-800 font-semibold duration-300 mr-3" @click="saveFile">Save</button>
                    <button class="cursor-pointer text-xs text-gray-400 hover:text-gray-700 duration-300" @click="cancelEdit">Cancel</button>
                  </div>
                  <div v-else class="flex items-center">
                    <div>{{doc.title}}</div>
                    <div v-if="$can('manage', 'Shipment')" class="ml-2" @click="editFile(doc)"><svg-edit class="w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
                  </div>
                </div>
                <div class="text-gray-500 text-xs">{{doc.filename}}</div>
              </div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              {{doc.uploaded_at}}
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div class="flex items-center justify-end">
                <div @click="download($event, doc.url, doc.filename)"><svg-download class="w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
                <div v-if="$can('manage', 'Shipment')" @click="deleteFile($event, doc.id, index)"><svg-trash class="ml-5 w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!documents.length" class="px-4 py-5 text-gray-500 text-base">No documents</div>
  </div>
</template>

<script>
  import { ShipmentEventBus } from '../helpers/shipment-event-bus.js'
  import axios from 'axios';

  export default {
    data() {
      return {
        style: this.$store.state.style,
        isUploading: false,
        uploadProgress: null,
        editingDoc: null,
      }
    },

    components: {
    },
    props: {
      documents: {type: Array},
      shipment: {type: Object},
    },
    methods: {
      cancelEdit() {
        this.editingDoc = null
      },
      editFile(doc) {
        this.editingDoc = {...doc}
      },
      saveFile() {
        console.log('save file', this.editingDoc.id, this.editingDoc.title)
        this.$ax.put('documents/'+this.editingDoc.id, {document: {title: this.editingDoc.title}})
          .then(res => {
            console.log(res)
            if (res.status==200) {
              this.cancelEdit()
              ShipmentEventBus.$emit('reloadData')
            }
          })
      },

      download(e, url, filename) {
        console.log('download url',url)
        let path = this.$store.state.path[this.$store.state.env]
        console.log('path', path)
        axios.get(path+url.substring(1), {responseType: 'blob'})
          .then((response) => {
            console.log('download response', response )
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
        });
      },
      deleteFile(e, id, index) {
        console.log('deleting file id:', id)
        let c = confirm("Delete this file?")
        if (c) {
          // this.$ax.delete(`shipments/${shipment_id}/documents/${id}`)
          this.$ax.delete(`documents/${id}`)
            .then(res => {
              console.log('delete res', res)
              this.$emit('updateDoc', 'delete doc', index)
            })
          }
      },
      triggerUpload() {
        document.getElementById("uploadButton").click();
      },
      uploadFile(e) {
        // console.log('ref file', this.$refs.file.files)
        let files = e.target.files
        console.log('files', files)
        // let file = e.target.files[0]
        let formData = new FormData();
        formData.append('shipment_id', this.shipment.id)

        let documents = []

        Array.from(files).forEach((file) => formData.append('files[]', file))
        console.log('documents array', documents)

        // const config = {
        //     onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        // }

        const _this = this

        var config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            _this.isUploading = true
            _this.uploadProgress = percentCompleted
            console.log('uploading', percentCompleted)
            console.log('file upload', progressEvent)
          }
        };

        this.$ax.post('documents', formData, config)
          .then(res => {
            console.log('upload file res', res)
            this.isUploading = false
            this.uploadProgress = null
            this.$emit('updateDoc', 'append doc', res.data.documents)
          })

      },
    }
  }
</script>
