<template>
  <div class="relative bg-white">
      <div class="text-black text-lg font-bold mb-10">General</div>
      <!-- Shipment Types -->
      <div class="mb-6">
          <div class="mb-2" :class="style.form.label">SHIPMENT TYPE *</div>
          <div class="lg:flex lg:justify-between">
              <template v-for="mode, index in transportationModes">
                <div v-bind:key="index" class="flex items-center justify-center cursor-pointer bg-gray-100 rounded-lg my-2 py-2 px-4" style="width: 250px; height: 80px" :class="shipment_type == mode ? selectedStyle : ''" @click="addToFormData($event, 'shipment_type', mode)">
                    <div class="mr-4">
                        <svg-air-mode v-if="mode == 'Air'" class="text-darkgray-500 fill-current" />
                        <svg-truck-mode v-if="mode == 'Truck'" class="text-darkgray-500 fill-current" />
                        <svg-ocean-mode v-if="mode == 'Ocean'" class="text-darkgray-500 fill-current" />
                    </div>
                    <div class="text-darkgray-500 font-bold">{{mode}}</div>
                </div>
              </template>
          </div>
      </div>
      <!-- Containers -->
      <div class="mb-6">
          <div class="mb-2" :class="style.form.label">CONTAINER SIZE *</div>
          <div class="lg:flex lg:justify-between">
            <template v-for="size, index in containerSizes">
                <div v-bind:key="index" class="flex items-center flex-shrink-0 cursor-pointer bg-gray-100 rounded-lg my-2 py-2 px-4" style="width: 380px; height: 100px" :class="container_size == size.title ? selectedStyle : ''" @click="addToFormData($event, 'container_size', size.title)">
                    <div class="mr-4">
                        <svg-fcl v-if="size.title == 'FCL'"/>
                        <svg-lcl v-if="size.title == 'LCL'"/>
                    </div>
                    <div>
                        <div class="text-darkgray-500 font-bold">{{size.title}}</div>
                        <div class="text-darkgray-500">{{size.description}}</div>
                    </div>
                </div>
            </template>
          </div>
      </div>
      <!-- Incoterms -->
      <div class="mb-4">
          <div class="mb-2" :class="style.form.label">INCOTERMS</div>
          <div class="lg:flex">
              <div class="flex items-center justify-center cursor-pointer bg-gray-100 text-darkgray-500 font-bold rounded-lg my-2 mr-4 py-2 px-4" style="height: 45px; width: 150px" @click="addToFormData($event, 'incoterm', 'FOB')" :class="incoterm == 'FOB' ? selectedStyle : ''">
                  FOB
              </div>
              <div class="flex items-center justify-center cursor-pointer bg-gray-100 text-darkgray-500 font-bold rounded-lg my-2 mr-4 py-2 px-4" style="height: 45px; width: 150px" @click="addToFormData($event, 'incoterm', 'EXW')" :class="incoterm == 'EXW' ? selectedStyle : ''">
                  EXW
              </div>
              <select name="" id="incoterm" class="appearance-none flex items-center cursor-pointer w-full bg-gray-100 text-darkgray-500 font-bold rounded-lg my-2 mr-4 py-2 px-4" style="height: 45px; width: 150px;" @change="addToFormData($event, 'incoterm', $event.target.value)" :class="isOtherIncoterm ? selectedStyle : ''">
                <option value="incoterm" selected disabled>Other</option>
                <option v-for="incoterm in incoterms" :key="incoterm" :value="incoterm">{{incoterm}}</option>
              </select>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            style: this.$store.state.style,
            selectedStyle: 'border border-sky-500 bg-sky-500 bg-opacity-25',
            transportationModes: [
                'Air',
                'Ocean',
                'Truck'
            ],
            containerSizes: [
                {
                    title: 'FCL',
                    description: 'Full Container'
                },
                {
                    title: 'LCL',
                    description: 'Less than a full container'
                }

            ],
            incoterms: [
                'FCA',
                'CPT',
                'CIP',
                'DAP',
                'DPU',
                'DDP',
                'FAS',
                'CFR',
                'CIF'
            ],
            formData: {},
            shipment_type: '',
            container_size: '',
            incoterm: ''
        }
    },

    computed: {
        isOtherIncoterm() {
            return this.incoterm != 'FOB' && this.incoterm != 'EXW' && this.incoterm != ''
        }
    },

    methods: {
        addToFormData(e, key, value) {
            if (this.formData[key] == value) {
                this[key] = ''
                this.formData[key] = ''
            } else {
                this.formData[key] = value
                this[key] = value
            }
            this.$emit('general-data', this.formData)
        }

    }

}
</script>

<style scoped>

select {
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}
option:focus {
   outline: 0;
 }

</style>