<template>
  <div class="flex">
    <div class="mr-3 relative flex flex-col justify-start" >
      <div :class="[bold?'bg-gray-900':'bg-gray-300']"
        class="w-4 h-4 rounded-full flex-shrink-0"></div>
      <div :class="[status.is_completed?'border-gray-900':'border-gray-300']"
        class="border-l border-dashed h-full relative"
        style="left: 0.45rem;"
        v-if="!last&&!status.current"></div>
      <div v-if="status.current&&!last" class="h-full flex flex-col justify-center">
        <div class="border-gray-900 border-l border-dashed relative"
          style="left: 0.45rem;height: 50%"></div>
        <div class="border-gray-300 border-l border-dashed relative"
          style="left: 0.45rem;height: 50%"></div>
        <div class="w-8 h-8 p-1 bg-blue-400 current-status">
          <svg-train v-if="carrier=='train'" class="w-full h-full duration-300 text-white fill-current" />
          <svg-ship v-if="carrier=='ship'" class="w-full h-full duration-300 text-white fill-current" />
          <svg-plane v-if="carrier=='air'" class="w-full h-full duration-300 text-white fill-current" />
          <svg-truck v-if="carrier=='truck'" class="w-full h-full duration-300 text-white fill-current" />
        </div>
      </div>

      <!-- <div v-if="status.is_final_destination" :class="{ 'bg-gray-900': status.is_completed }" class="w-4 h-4 rounded-full bg-gray-300 flex-shrink-0"></div> -->
    </div>

    <!-- <div v-else class="mb-8 w-full" style="margin-top: -0.5rem;"> -->
    <div class="w-full">
      <div class="mb-8 w-full relative" style="margin-top: -0.5rem;">
        <div class="flex justify-between">
          <div>{{last ? status.destination_city : status.origin_city}}</div>
          <div class="flex items-center" v-if="$can('manage', 'Shipment')">
            <div class="w-5 h-5" @click="editStatus" v-if="!last">
              <svg-edit class="w-full h-full cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" />
            </div>
            <div class="cursor-pointer ml-3 w-5 h-5" @click="deleteStatus" v-if="!last">
              <svg-trash class="h-full w-full cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" />
            </div>
          </div>
        </div>
        <div class="text-gray-500 text-sm">{{last ? status.destination_location_title : status.origin_location_title}}</div>
        <div class="text-gray-500 text-xs">{{last ? status.destination_location_detail : status.origin_location_detail}}</div>

        <!-- ATA BOX -->
        <div class="mt-3 bg-gray-200 border py-2 px-3 text-sm w-full" v-if="last&&(shipment.ata_date||shipment.final_delivery_date)">
          <div class="flex justify-between items-center">
            <div class="w-1/2 flex flex-col">
              <div class="text-gray-500 text-xs">ATA</div>
              <div class="text-sm break-words">{{shipment.ata_date||'TBC'}}</div>
            </div>

            <div class="w-1/2 flex flex-col">
              <div class="text-gray-500 text-xs">FINAL DELIVERY DATE</div>
              <div class="text-sm break-words">{{shipment.final_delivery_date||'TBC'}}</div>
            </div>
          </div>
        </div>

        <!-- DETAIL BOX -->
        <div class="mt-3 bg-gray-200 border py-2 px-3 text-sm w-full" v-if="!last && !isForm">
          <div class="flex justify-between items-center cursor-pointer relative"
            @click="toggleShowMore()">

            <div v-if="status.atd_date" class="w-1/2 flex flex-col">
              <div class="text-gray-500 text-xs">ATD</div>
              <div class="text-sm break-words">{{status.atd_date}}</div>
            </div>
            <div v-else class="w-1/2 flex flex-col">
              <div class="text-gray-500 text-xs">ETD</div>
              <div class="text-sm break-words">{{status.etd_date}}</div>
            </div>

            <div class="w-1/2 flex flex-col">
              <div class="text-gray-500 text-xs">ETA</div>
              <div class="text-sm break-words">{{status.eta_date}}</div>
            </div>

            <svg-arrow-down class="w-2 h-2 text-gray-800 fill-current duration-300 transform absolute right-0"
              :class="{'rotate-180': showMore}" />
          </div>

          <!-- DETAIL BOX SHOW MORE -->
          <div class="mt-3" v-if="showMore">
            <div class="flex">
              <div class="w-1/2 flex flex-col">
                <div class="text-gray-500 text-xs">CARRIER</div>
                <div class="text-sm break-words">{{status.carrier.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- EDIT STATUS FORM -->
        <div class="mt-3">
          <status-form @statusForm="appendStatus" v-if="isForm" :shipment="shipment" :status="status" :isNew=false :index="index"/>
          <slot />
        </div>
        <!-- IF FINAL DESTINATION -->
        <!-- <div style="bottom: -68px;" class="absolute">
          <div>{{status.destination_city}}</div>
          <div class="text-gray-500 text-sm mb-3">{{status.destination_location_title}}</div>
        </div> -->
      </div>

      <div v-if="status.current&&!last&&$can('manage', 'Shipment')" class='mb-2 inline-block cursor-pointer text-xs bg-transparent text-blue-400 hover:text-blue-700 font-semibold duration-300' @click="completeStatus">Reach destination</div>
    </div>

  </div>
</template>

<script>
  import { ShipmentEventBus } from '../helpers/shipment-event-bus.js'
  import { mapState } from 'vuex'
  import StatusForm from './status_form'

  export default {
    data() {
      return {
        showMore: false,
        isForm: false,
      }
    },

    props: {
      status: { type: Object },
      shipment: { type: Object },
      index: { type: Number },
      last: { type: Boolean, default: false }
      // isForm: { type: Boolean, default: false }
    },

    computed: {
      ...mapState(['style']),
      bold() {
        if (this.status.is_completed) {
          return true
        } else if (this.status.current && !this.last) {
          return true
        } else {
          return false
        }
      },
      carrier() {
        return this.status.carrier.carrier_type
      }
    },

    components: {
      StatusForm
    },

    methods: {

      completeStatus() {
        console.log('id',this.shipment.id)
        this.$ax.put(`shipments/${this.shipment.id}/shipping_statuses/${this.status.id}`, {shipping_status: {is_completed: true}})
          .then(res => {
            console.log(res)
            ShipmentEventBus.$emit('completeStatus')
          })
      },

      toggleShowMore() {
        this.showMore = !this.showMore
      },
      editStatus() {
        console.log('editing')
        this.isForm = !this.isForm
      },

      deleteStatus() {
        console.log('deleting. index', this.index)
        let c = confirm("Delete this status?")
        if (c) {
          this.$ax.delete(`shipping_statuses/${this.status.id}`)
            .then(res => {
              console.log(res)
              this.$emit('statusForm', 'remove status', this.index)
            })
        }
      },

      appendStatus(action, value) {
        console.log('appendStatus in shipping status', action, value)
        this.isForm = false
        this.createNew = false
        this.$emit('statusForm', action, value)
      }
    }
  }
</script>

<style>
  .current-status {
    position: absolute;
    top: 42%;
    right: 0;
    /*background: white;*/
  }
</style>

<!-- overflow-wrap: break-word;
  word-wrap: break-word; -->
