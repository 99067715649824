<template>
  <div>
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 10%;">SHIPMENT NO.</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">NAME</th>
          <th class="px-4 py-2 text-center text-gray-500 text-xs" style="width: 20%;">SHIPMENT TYPE</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">LOADING PORT</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">FINAL DESTINATION</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">STATUS</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="quote in quotes">
          <tr @click="goToQuote($event, quote.slug)" :data-slug="quote.slug" class="bg-white hover:bg-blue-100 duration-300 cursor-pointer text-sm">
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{quote.shipment_no || '-'}}</div>
              <div class="text-gray-500 text-sm" v-if="$can('manage', 'Shipment')">{{quote.client.name}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{quote.shipment_no}}</div>
              <div>{{quote.pos||'-'}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div class="flex justify-center items-center">
                <svg-air-mode class="w-8 h-8 text-sky-500 fill-current" v-if="quote.shipment_type == 'Air'" />
                <svg-truck-mode class="w-8 h-8 text-sky-500 fill-current" v-if="quote.shipment_type == 'Truck'" />
                <svg-ocean-mode class="w-8 h-8 text-sky-500 fill-current" v-if="quote.shipment_type == 'Ocean'" />
                <div v-if="quote.shipment_type == null"> - </div>
              </div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{quote.loading_port}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{quote.final_destination}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div class="text-sky-500 text-left">{{quote.is_confirmed ? 'Shipment Confirmed' : quote.status}}</div>
              <template v-if="$can('manage', 'Shipment') && quote.status == 'Quote Accepted' && !quote.is_confirmed">
                <button :class="style.btn.ghostSm" class="mt-3" @click.stop="confirmShipment($event, quote)">Confirm Shipment</button>
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!quotes.length" class="px-4 py-5 text-gray-500 text-base">No quotes</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        style: this.$store.state.style
      }
    },

    props: {
      quotes: { type: Array, default: () => [] }
    },

    methods: {
      goToQuote(e, slug) {
        this.$router.push({ name: 'shipment', params: { slug }})
      },

      confirmShipment(e, quote) {
        this.$emit('confirm-shipment', quote)
      }
    }
  }
</script>
