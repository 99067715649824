<template>
    <div class="bg-gray-100 p-10 min-h-full">
        <page-title :title="customer.name" :btnText="btnText" btn="customerStaffCreate" />
        <search-bar placeholder="Search on staff name, email, phonenumber" class="mb-10" @search="searchStaff"/>
        <div class="fixed bg-gray-900 opacity-25 inset-0" v-if="showMore" @click="hideOptions"></div>

        <table class="table-fixed w-full">
        <thead>
            <tr>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">FIRST NAME</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">LAST NAME</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 25%;">EMAIL</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">PHONE</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">ROLE</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="member, index in customer.staff" class="bg-white hover:bg-blue-100 duration-300 text-sm">
            <td class="px-4 py-6 border-gray-100 row-border align-top">
                {{member.first_name}}
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
                {{member.last_name}}
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
                {{member.email}}
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
                {{member.phone_number}}
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
                {{member.role == 'client' ? 'Staff' : 'Representative'}}
            </td>
            <td class="pl-4 py-6 pr-8 border-gray-100 row-border align-top">
                <div class="relative text-right pr" v-if="$can('manage', 'User')">
                <button class="border rounded w-6 h-6 duration-300 hover:bg-gray-400 cursor-pointer" @click="showOptions(index)">...</button>
                <div class="absolute bg-white border top-0" style="right: 1.7rem;" v-if="member.showOptions">
                    <!-- <div class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Resend password</div> -->
                    <!-- <div v-if="member.role != 'representative'" @click="makeRepresentative(member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Make Representative</div>
                    <div v-else @click="makeRepresentative(member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Make Staff</div> -->
                    <div @click="editStaff(member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Edit</div>
                    <div v-if="member.role != 'representative'" @click="deleteStaff($event, member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Delete</div>
                </div>
                </div>
            </td>
            </tr>
        </tbody>
        </table>

        <form-modal v-if="showModal" @toggleModal="toggleModal">
            <staff-edit :staff="staffToEdit" />
        </form-modal>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import FormModal from '../components/form_modal';
import StaffEdit from './components/staff_edit'

export default {
    data() {
        return {
            btnText: "Create New Staff",
            showMore: false,
            showModal: false,
            staffToEdit: '',
            searchQuery: null,
            customer: {}

        }
    },

    components: {
        FormModal, StaffEdit
    },

    mounted() {
        this.btnText = this.$can('manage', 'User') ? "Create New Staff" : ""
        this.getData()
    },

    computed: {
        ...mapState(['user'])
    },

    methods: {
        getData() {
            const { slug } = this.$route.params;
            this.$ax.get('staff/' + slug)
            .then(res => {
                const { client , users } = res.data
                const customer = {}
                Object.assign(customer, { staff: users, name: client.name, id: client.id })
                this.$store.state.client = client
                this.customer = customer
            }).catch(() => this.$router.push({ name: 'shipments'}))
        },

        searchStaff(res) {
            this.getStaff(res)
        },

        toggleModal(showModal) {
            this.showModal = showModal
        },

        showOptions(index) {
            this.showMore = true
            this.customer.staff.map(c=>c.showOptions = false)
            this.customer.staff[index].showOptions = true
            this.customer.staff = [...this.customer.staff]
        },

        hideOptions() {
            this.showMore = false
            this.customer.staff.forEach(c=>c.showOptions = false)
        },

        getStaff(query) {
            const { slug } = this.$route.params;
            let path = 'staff/' + slug
            if (query) path += `?query=${query}`

            this.$ax.get(path)
                .then(res => {
                const { client , users } = res.data
                this.customer.staff = users
                })
        },

        makeRepresentative(member) {
            let c = confirm('Make this staff a representative?')
            // if (c) console.log(member)
        },

        editStaff(s) {
            this.hideOptions()
            this.showModal = true
            this.staffToEdit = s
        },

        deleteStaff(e, member) {
            let c = confirm("Delete this staff member?")
            if (c) {
                this.$ax.delete(`staff/${member.id}`)
                .then(res=>{
                    this.hideOptions()
                    this.showModal = false
                    this.getStaff();
                })
            }
        }

    }

}
</script>

<style>

</style>