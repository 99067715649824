// const btnGhostDefault = 'bg-transparent hover:bg-blue-700 text-blue-800 font-semibold hover:text-white py-2 px-4 border border-blue-700 hover:border-transparent duration-300'
const btnGhostDefault = 'bg-transparent hover:bg-sky-500 text-sky-500 font-semibold hover:text-white py-2 px-4 border border-sky-500 hover:border-transparent duration-300'
// const btnFillDefault = 'bg-blue-600 hover:bg-blue-800 text-white font-semibold py-2 px-4 border duration-300'
const btnFillDefault = 'bg-sky-500 hover:bg-sky-800 text-white font-semibold py-2 px-4 border duration-300'
const btnDisabledDefault = 'bg-gray-100 text-white text-gray-500 font-semibold py-2 px-4 border cursor-default'

const style = {
  btn: {
    ghost: btnGhostDefault + ' text-sm',
    ghostSm: btnGhostDefault + ' text-xs',
    fill: btnFillDefault + ' text-sm',
    fillSm: btnFillDefault + ' text-xs',
    empty: 'text-xs text-gray-500 hover:text-red-700 font-semibold py-2 px-4 duration-300',
    disabled:  btnDisabledDefault + ' text-sm',
    disabledSm:  btnDisabledDefault + ' text-xs',
  },
  form: { label: 'text-sm text-gray-500 mb-1', input: 'outline-none text-base px-3 py-1 border border-gray-300' },
  icon: { sm: 'text-gray-400 fill-current h-5 w-5' }
}

export {
  style
}
