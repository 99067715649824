<template>
  <div>
    <div class="text-xl mb-5 pt-3">Edit Customer</div>
    <customer-form :customerToEdit="customer" />
  </div>
</template>

<script>
  import CustomerForm from './components/form';

  export default {
    data() {
      return {
      }
    },

    props: {
      customer: { type: Object, required: true },
    },

    components: {
      CustomerForm
    }
  }
</script>
