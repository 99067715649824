<template>
  <div>
      <div class="flex justify-start items-center mb-5" v-if="$can('manage', 'Shipment')">
      <!-- <button class="mr-5" :class="style.btn.ghostSm">Download all</button> -->
        <button @click="toggleModal"
            :class="style.btn.ghostSm">New Quote</button>
      </div>
      <div v-if="quotes.length">
        <table class="table-fixed w-full">
        <thead>
            <tr>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;"></th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">Quote</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">Carrier</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">Transit</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">Total</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">Expires</th>
            <th v-if="$can('manage', 'Shipment')" class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">Status</th>
            <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 10%;"></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="quote, index in quotes">
            <tr class="text-sm cursor-pointer" @click="toggleRowDetails($event, index)">
                <td class="pr-4 py-4 align-top">
                    <svg-small-airplane v-if="quote.mode == 'Air'"/>
                    <svg-small-truck v-if="quote.mode == 'Truck'"/>
                    <svg-small-ship v-if="quote.mode == 'Ocean'"/>
                </td>
                <td class="px-4 py-4 border-b align-top">
                    {{quote.ref}}
                </td>
                <td class="px-4 py-4 border-b align-top">
                    {{quote.carrier_name}}
                </td>
                <td class="px-4 py-4 border-b align-top">
                    {{quote.transit || getTransitDays(quote)}} days
                </td>
                <td class="px-4 py-4 border-b align-top font-bold">
                    {{quote.total | currency}}
                </td>
                <td class="px-4 py-4 border-b align-top">
                    {{quote.expiry_date}}
                </td>
                <td v-if="$can('manage', 'Shipment')" class="px-4 py-4 border-b align-top">
                    {{quote.status || 'Pending Client Approval'}}
                </td>
                <template v-if="$can('manage', 'Shipment')">
                    <td class="border-b">
                        <div class="flex justify-center" v-if="!quote.status">
                            <div class="mr-4" @click.prevent="editQuote($event, quote)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1667 6.11667L13.8834 3.83334C13.5854 3.55342 13.1949 3.3928 12.7862 3.38205C12.3775 3.37129 11.9791 3.51114 11.6667 3.775L4.16674 11.275C3.89738 11.5466 3.72966 11.9027 3.69174 12.2833L3.33341 15.7583C3.32218 15.8804 3.33802 16.0034 3.37979 16.1187C3.42156 16.2339 3.48824 16.3385 3.57507 16.425C3.65294 16.5022 3.74529 16.5633 3.84682 16.6048C3.94835 16.6463 4.05707 16.6673 4.16674 16.6667H4.24174L7.71674 16.35C8.0974 16.3121 8.45343 16.1444 8.72507 15.875L16.2251 8.375C16.5162 8.06748 16.6735 7.65709 16.6626 7.23378C16.6516 6.81047 16.4733 6.40876 16.1667 6.11667ZM13.3334 8.9L11.1001 6.66667L12.7251 5L15.0001 7.275L13.3334 8.9Z" fill="#6D6D6D"/>
                                </svg>
                            </div>
                            <div class="cursor-pointer" @click.prevent="removeQuote($event, index)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 15.8333C5 16.2754 5.17559 16.6993 5.48815 17.0118C5.80071 17.3244 6.22464 17.5 6.66666 17.5H13.3333C13.7754 17.5 14.1993 17.3244 14.5118 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V5.83333H5V15.8333ZM7.05 9.9L8.225 8.725L10 10.4917L11.7667 8.725L12.9417 9.9L11.175 11.6667L12.9417 13.4333L11.7667 14.6083L10 12.8417L8.23333 14.6083L7.05833 13.4333L8.825 11.6667L7.05 9.9ZM12.9167 3.33333L12.0833 2.5H7.91666L7.08333 3.33333H4.16666V5H15.8333V3.33333H12.9167Z" fill="#6D6D6D"/>
                                </svg>
                            </div>
                        </div>
                    </td>
                </template>
                <template v-else>
                    <td v-if="quote.status" class="px-4 py-4 border-b align-top">
                        {{quote.status}}
                    </td>
                    <td v-else class="px-4 py-4 border-b align-top text-right">
                        <button @click="bookQuote($event, quote.id)" :class="style.btn.fillSm">Book</button>
                    </td>
                </template>
            </tr>
            <tr>
                <td colspan="7">
                    <div v-if="index == selectedRow" class="p-10">
                        <quote-details :quote="quote" />
                    </div>
                </td>
            </tr>
            </template>
        </tbody>
        </table>
      </div>

    <div v-if="quotes.length == 0" class="px-4 py-5 text-gray-500 text-base text-center">You haven't received any quotes yet.</div>
    <form-modal v-if="showModal" @toggleModal="toggleModal">
        <quote-form :shipment="shipment" />
    </form-modal>
    <form-modal v-if="showEdit" @toggleModal="toggleEdit">
        <quote-edit :shipment="shipment" :quoteToEdit="quoteToEdit" />
    </form-modal>
  </div>
</template>

<script>
import QuoteDetails from './quote_details'
import FormModal from '../../components/form_modal';
import QuoteForm from './quote_form'
import QuoteEdit from './quote_edit'


export default {
    data() {
        return {
            dropdown: { height: 0 },
            selectedRow: null,
            style: this.$store.state.style,
            showModal: false,
            showEdit: false,
            quoteToEdit: null   
        }
    },

    props: {
        shipment: {type: Object},
        quotes: {type: Array, default: []}
    },

    components: {
        QuoteDetails, FormModal, QuoteForm, QuoteEdit
    },

    methods: {
        toggleRowDetails(e, index) {
            if (this.selectedRow == index) {
                this.selectedRow = null
                return
            }
            this.selectedRow = index
        },

        toggleModal(showModal) {
            this.showModal = showModal
        },

        getTransitDays(quote) {
            const { port_to_port, origin, destination } = quote
            return Number.parseInt(port_to_port) + Number.parseInt(origin) + Number.parseInt(destination)
        },

        bookQuote(e, id) {
            let c = confirm('Are you sure you would like to accept this quote?')
            if (c) {
                this.$ax.post(`quotes/${id}`, {status: 'confirmed', shipment_id: this.shipment.id})
                .then(res => {
                    this.processData(res)
                })
            }
        },

        processData(res) {
            if (res.status == 200) {
                this.$router.go()
            }
        },

        removeQuote(e, index) {
            e.stopPropagation()
            let c = confirm('Are you sure you would like to remove this quote?')
            if (c) {
                this.$ax.delete(`quotes/${this.quotes[index].id}`)
                .then(res => {
                    console.log(res)
                    this.quotes.splice(index,1)
                })
            }
        },

        editQuote(e, quote) {
            e.stopPropagation()
            this.showEdit = !this.showEdit
            this.quoteToEdit = quote
            // console.log(quote)
        },

        toggleEdit(showModal) {
            this.showEdit = showModal
        }
    }

}
</script>

<style>

</style>