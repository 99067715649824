<template>
    <div class="bg-white relative border-l">

        <div class="text-black text-lg font-semibold mb-4">Cargo Details</div>
        <div class="md:flex md:justify-between mb-4">
            <div class="flex flex-col mb-4 mr-2">
                <label class="font-semibold" :class="style.form.label">TOTAL WEIGHT</label>
                <input placeholder="(kg)" type="number" min="0" :class="style.form.input" class="bg-gray-100 px-4 py-2 w-full" name="weight" @change="addToFormData">
            </div>
            <div class="flex flex-col mb-4">
                <label class="font-semibold" :class="style.form.label">TOTAL VOLUME</label>
                <input placeholder="(cbm)" type="number" min="0" :class="style.form.input" class="bg-gray-100 px-4 py-2 w-full" name="cbm" @change="addToFormData">
            </div>
        </div>

        <div class="text-black text-lg font-semibold mb-4">Packages</div>
        <div class="relative">
            <template v-for="p, index in packages">
                <div class="flex justify-between mb-4">
                    <div>
                        <div>{{p.package_name}}</div>
                        <div>{{p.length}}x{{p.width}}x{{p.height}}cm/{{p.weight}}kg</div>
                    </div>
                    <div class="flex">
                        <div>
                            <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.1667 6.11667L13.8834 3.83334C13.5854 3.55342 13.1949 3.3928 12.7862 3.38205C12.3775 3.37129 11.9791 3.51114 11.6667 3.775L4.16674 11.275C3.89738 11.5466 3.72966 11.9027 3.69174 12.2833L3.33341 15.7583C3.32218 15.8804 3.33802 16.0034 3.37979 16.1187C3.42156 16.2339 3.48824 16.3385 3.57507 16.425C3.65294 16.5022 3.74529 16.5633 3.84682 16.6048C3.94835 16.6463 4.05707 16.6673 4.16674 16.6667H4.24174L7.71674 16.35C8.0974 16.3121 8.45343 16.1444 8.72507 15.875L16.2251 8.375C16.5162 8.06748 16.6735 7.65709 16.6626 7.23378C16.6516 6.81047 16.4733 6.40876 16.1667 6.11667ZM13.3334 8.9L11.1001 6.66667L12.7251 5L15.0001 7.275L13.3334 8.9Z" fill="#6D6D6D"/>
                            </svg> -->
                        </div>
                        <div class="cursor-pointer" @click="removePackage(index)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 15.8333C5 16.2754 5.17559 16.6993 5.48815 17.0118C5.80071 17.3244 6.22464 17.5 6.66666 17.5H13.3333C13.7754 17.5 14.1993 17.3244 14.5118 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V5.83333H5V15.8333ZM7.05 9.9L8.225 8.725L10 10.4917L11.7667 8.725L12.9417 9.9L11.175 11.6667L12.9417 13.4333L11.7667 14.6083L10 12.8417L8.23333 14.6083L7.05833 13.4333L8.825 11.6667L7.05 9.9ZM12.9167 3.33333L12.0833 2.5H7.91666L7.08333 3.33333H4.16666V5H15.8333V3.33333H12.9167Z" fill="#6D6D6D"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
            <div>
                <div class="flex flex-col mb-4">
                        <label class="font-semibold" :class="style.form.label">PACKAGE DETAILS (00cartons = 00pallets / or wooden case )</label>
                        <input placeholder="" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="package_name" @change="createPackage">
                </div>
                <div class="flex justify-between mb-4">
                    <div class="flex flex-col mb-2 mr-2">
                        <label class="font-semibold" :class="style.form.label">LENGTH</label>
                        <input placeholder="(cm)" type="number" min="0" :class="style.form.input" class="package bg-gray-100 w-full" name="length" @change="createPackage">
                    </div>
                    <div class="flex flex-col mb-2">
                        <label class="font-semibold" :class="style.form.label">WIDTH</label>
                        <input placeholder="(cm)" type="number" min="0" :class="style.form.input" class="package bg-gray-100 w-full" name="width" @change="createPackage">
                    </div>
                </div>
                <div class="flex justify-between mb-4">
                    <div class="flex flex-col mb-2 mr-2">
                        <label class="font-semibold" :class="style.form.label">HEIGHT</label>
                        <input placeholder="(cm)" type="number" min="0" :class="style.form.input" class="package bg-gray-100 w-full" name="height" @change="createPackage">
                    </div>
                    <div class="flex flex-col mb-2">
                        <label class="font-semibold" :class="style.form.label">WEIGHT</label>
                        <input placeholder="(kg)" type="number" min="0" :class="style.form.input" class="package bg-gray-100 w-full" name="weight" @change="createPackage">
                    </div>
                </div>
            </div>
            <div class="pb-2 flex justify-end">
                <button class="" :class="style.btn.fillSm" @click="addPackage">Add</button>
            </div>
        </div>

        <div class="text-black text-lg font-semibold mb-4">Upload Documents</div>
        <div class="my-4" v-if="documents.length">
            <div v-for="doc, index in documents" class="flex justify-between my-2">
            <div class="text-base">{{doc}}</div>
            <div @click="deleteFile(doc)"><svg-trash class="ml-5 w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div>
            </div>
        </div>
        <input id="uploadButton" ref="file" type="file" style="display:none" @change="uploadFile($event)" multiple>
        <div class="flex justify-between">
            <div class="flex items-center rounded-lg cursor-pointer bg-gray-100 px-4 py-2 mr-2" style="width: 188px;" @click="triggerUpload">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 22.5883C6.93044 22.5883 2.41174 18.0696 2.41174 12.5C2.41174 6.93044 6.93044 2.41174 12.5 2.41174C18.0696 2.41174 22.5883 6.93044 22.5883 12.5C22.5883 18.0696 18.0696 22.5883 12.5 22.5883ZM5.35417 12.5C5.35417 16.4407 8.55928 19.6458 12.5 19.6458C16.4407 19.6458 19.6458 16.4407 19.6458 12.5C19.6458 8.55928 16.4407 5.35415 12.5 5.35415C8.55928 5.35415 5.35417 8.55928 5.35417 12.5ZM16.7035 12.5H14.1814V17.1238C14.1814 17.3603 13.9975 17.5441 13.761 17.5441H11.239C11.0025 17.5441 10.8186 17.3603 10.8186 17.1238V12.5H8.29656C8.06011 12.5 7.87622 12.3161 7.87622 12.0797C7.87622 11.9614 7.92876 11.8564 8.00757 11.7644L12.1979 7.5741C12.2898 7.49529 12.3949 7.45588 12.5 7.45588C12.6051 7.45588 12.7102 7.49529 12.8021 7.5741L17.0056 11.7775C17.1238 11.9089 17.1632 12.0797 17.0975 12.2373C17.0319 12.3949 16.8742 12.5 16.7035 12.5Z" fill="#808080"/>
                </svg>
                <div class="text-darkgray-500 font-semibold ml-2">
                    Packing List
                </div>
            </div>
            <div class="flex items-center rounded-lg cursor-pointer bg-gray-100 px-4 py-2" style="width: 188px;" @click="triggerUpload">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 22.5883C6.93044 22.5883 2.41174 18.0696 2.41174 12.5C2.41174 6.93044 6.93044 2.41174 12.5 2.41174C18.0696 2.41174 22.5883 6.93044 22.5883 12.5C22.5883 18.0696 18.0696 22.5883 12.5 22.5883ZM5.35417 12.5C5.35417 16.4407 8.55928 19.6458 12.5 19.6458C16.4407 19.6458 19.6458 16.4407 19.6458 12.5C19.6458 8.55928 16.4407 5.35415 12.5 5.35415C8.55928 5.35415 5.35417 8.55928 5.35417 12.5ZM16.7035 12.5H14.1814V17.1238C14.1814 17.3603 13.9975 17.5441 13.761 17.5441H11.239C11.0025 17.5441 10.8186 17.3603 10.8186 17.1238V12.5H8.29656C8.06011 12.5 7.87622 12.3161 7.87622 12.0797C7.87622 11.9614 7.92876 11.8564 8.00757 11.7644L12.1979 7.5741C12.2898 7.49529 12.3949 7.45588 12.5 7.45588C12.6051 7.45588 12.7102 7.49529 12.8021 7.5741L17.0056 11.7775C17.1238 11.9089 17.1632 12.0797 17.0975 12.2373C17.0319 12.3949 16.8742 12.5 16.7035 12.5Z" fill="#808080"/>
                </svg>
                <div class="text-darkgray-500 font-semibold ml-2">
                    Invoice
                </div>
            </div>
        </div>

        <div class="text-black text-lg font-semibold mb-4 mt-8">Additional Remarks</div>
        <div class="mb-6">
            <textarea v-model="message" style="height: 100px;"
            type="text" :class="style.form.input" class="bg-gray-100 w-full" @change="emitAllData"></textarea>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            style: this.$store.state.style,
            formData: {},
            package: {

            },
            message: '',
            packages: [],
            fileData: '',
            showUpload: false,
            isUploading: false,
            uploadProgress: null,
            documents: [],
            documentsDictionary: {}
        }
    },

    mounted() {
    },

    methods: {
        addToFormData(e) {
            const { name, value } = e.target
            this.formData[name] = value
            this.emitAllData()
        },
        createPackage(e) {
            const { name, value } = e.target
            this.package[name] = value
        },

        addPackage() {
            if (Object.keys(this.package).length == 5) {
                this.packages.push(this.package)
                this.package = {}
                this.clearValues('package')
            } else {
                alert('Please fill in all the package information')
            }
            this.emitAllData()
        },

        removePackage(index) {
            this.packages.splice(index, 1)
            this.emitAllData()
        },

        clearValues(classname) {
            let elements = document.querySelectorAll(`.${classname}`)
            elements.forEach(element => {
                element.value = ""
            })
        },

        triggerUpload() {
            document.getElementById("uploadButton").click();
        },

        uploadFile(e) {
            let files = e.target.files
            if (this.fileData == '') this.fileData = new FormData();

            Array.from(files).forEach((file) => {
                this.fileData.append('files[]', file)
            })

            this.getCurrentDocuments()
            this.emitAllData()
        },

        getCurrentDocuments() {
            let currentDocs = this.fileData.getAll('files[]')
            let docNames = {}
            currentDocs.forEach((e,i) => {
                docNames[e.name] = i
            })
            this.documentsDictionary = docNames
            this.documents = Object.keys(docNames)
        },

        deleteFile(filename) {
            let c = confirm('Are you sure you would like to delete this file?')
            if (c) {
                let values = this.fileData.getAll("files[]");
                values.splice(this.documentsDictionary[filename], 1);
                this.fileData = new FormData
                values.forEach(e => this.fileData.append("files[]", e))
                this.getCurrentDocuments()
                this.emitAllData()
            }
        },
        emitAllData() {
            let form = {files: this.fileData, packages: this.packages}
            // let formData = Object.assign(this.formData, form )
            // this.$emit('quote-details', formData)
            let formData = Object.assign(this.formData, form )
            this.$emit('quote-details', {...this.formData, ...form, message: this.message})
        }
    }

}
</script>

<style scoped>
    select {
        text-align-last: center;
        text-align: center;
        -ms-text-align-last: center;
        -moz-text-align-last: center;
    }

    ::-webkit-input-placeholder {
            text-align: right;
        }
    :-moz-placeholder {
        text-align: right;
    }
</style>