<template>
  <form action="" style="width: 30rem;">
    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">FIRST NAME *</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="staff.first_name">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">LAST NAME *</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="staff.last_name">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">EMAIL *</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="staff.email">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">PHONE *</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="staff.phone_number">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">ROLE *</label>
      <div class="flex items-center">
        <input class="mr-2" type="radio" id="admin" value="admin" v-model="staff.role">
        <label for="admin" :class="style.form.label">ADMIN</label>
      </div>
      <div class="flex items-center">
        <input class="mr-2" type="radio" id="staff" value="staff" v-model="staff.role">
        <label for="staff" :class="style.form.label">STAFF</label>
      </div>
    </div>

    <!-- BUTTONS -->

    <div v-if="needNavBack" class="flex mt-5">
      <!-- <button type="button" class="w-full text-gray-500 text-sm duration-300 hover:text-blue-700 outline-none">Back</button> -->
      <button type="button" class="w-full" :class="style.btn.fill" @click="saveStaff('create')">
        Save and go back
      </button>
    </div>

    <button v-else-if="staffToEdit" type="button" class="w-full mt-5" :class="style.btn.fill" @click="saveStaff('edit')">Save</button>

    <button v-else type="button" class="w-full mt-5" :class="style.btn.fill" @click="saveStaff('create')">
      Save and email password
    </button>

    <div v-if="errorMessage" class="text-red-700 text-sm">{{errorMessage}}</div>

  </form>
</template>

<script>
  export default {
    data() {
      return {
        staff: {
          first_name: null, last_name: null, phone_number: null, email: null, role: null,
        },
        selectedRole: null,
        style: this.$store.state.style,
        errorMessage: null,
        validations: ['first_name', 'last_name', 'email', 'phone_number', 'role']
      }
    },
    components: {
      
    },

    props: {
      needNavBack: { type: Boolean, default: false },
      formType: { type: String, default: 'new' },
      staffToEdit: { type: Object }
    },

    mounted() {
      if (this.staffToEdit) this.staff = {...this.staffToEdit}
    },

    methods: {
      processData(res) {
        if (res.status==200) {
          console.log(res.data.client)
          if (this.needNavBack) {
            this.$emit('navBackWithData', res.data.client)
          } else {
            this.$router.go()
          }
        } else {
          this.errorMessage = "Oops, something went wrong, please try again."
        }
      },

      saveStaff(action) {
        const has_data = this.validations.every(k => this.staff[k])
        if (this.staff.role) this.staff.role.toLowerCase()

        if (has_data) {
          if (action == 'create') {
            this.$ax.post('managers', {user: this.staff})
              .then(res => {
                this.processData(res)
              })
          } else if (action == 'edit') {
            this.$ax.put('managers/'+this.staff.id, {user: this.staff})
              .then(res => {
                this.processData(res)
              })
          }
        } else {
          this.errorMessage = "Please input all details and try again. (*)"
        }
      }
    }
  }
</script>

<style>

</style>
