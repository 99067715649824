<template>
  <div class="bg-gray-100 p-10 min-h-full">
    <page-title v-if="adminRoles.includes(user.role)" title="Customers" btnText="New customer" btn="customerCreate" />
    <page-title v-else title="Customers" />

    <search-bar placeholder="Search on company name" class="mb-10" @search="searchCustomer"/>

    <div class="fixed bg-gray-900 opacity-25 inset-0" v-if="showMore" @click="hideOptions"></div>

    <table class="table-fixed w-full">
      <thead>
        <tr>
          <!-- <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;">LOGO</th> -->
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 10%;">REFERENCE</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">COMPANY</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">REPRESENTATIVE</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 25%;">EMAIL</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">PHONE</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer, index in customers" @click="goToCustomer($event, customer)" class="bg-white hover:bg-blue-100 duration-300 text-sm cursor-pointer">
          <!-- <td class="px-4 py-6 border-gray-100 row-border align-top">
            <img class="rounded-full" :src="customer.logo || 'https://picsum.photos/80/80'" alt="">
          </td> -->
          <td class="px-4 py-6 border-gray-100 row-border align-top">
            {{customer.code}}
          </td>
          <td class="px-4 py-6 border-gray-100 row-border align-top">
            {{customer.name}}
          </td>
          <td class="px-4 py-6 border-gray-100 row-border align-top">
            {{customer.representative}}
          </td>
          <td class="px-4 py-6 border-gray-100 row-border align-top">
            {{customer.email}}
          </td>
          <td class="px-4 py-6 border-gray-100 row-border align-top">
            {{customer.phone_number}}
          </td>
          <td class="pl-4 py-6 pr-8 border-gray-100 row-border align-top">
            <div class="relative text-right pr" v-can.manage.Client>
              <button class="border rounded w-6 h-6 duration-300 hover:bg-gray-400 cursor-pointer" @click.stop="showOptions(index)">...</button>
              <div class="absolute bg-white border top-0" style="right: 1.7rem;" v-if="customer.showOptions">
                <!-- <div class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Resend password</div> -->
                <div @click.stop="editCustomer(customer)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Edit</div>
                <div @click.stop="deleteCustomer($event, customer.id)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Delete</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <form-modal v-if="showModal" @toggleModal="toggleModal">
      <customer-edit :customer="customerToEdit" />
    </form-modal>
  </div>
</template>

<script>
  import FormModal from '../components/form_modal';
  import CustomerEdit from './edit'
  import { mapState } from 'vuex'


  export default {
    data() {
      return {
        showMore: false,
        customers: [],
        adminRoles: ['staff', 'admin'],
        showModal: false,
        searchQuery: null
      }
    },
    mounted() {
      this.getCustomers()
    },

    components: {
      FormModal, CustomerEdit
    },

    computed: {
      ...mapState(['user'])
    },

    methods: {
      searchCustomer(res) {
        // console.log('res', res)
        this.getCustomers(res)
      },
      toggleModal(showModal) {
        this.showModal = showModal
      },

      showOptions(index) {
        this.showMore = true
        this.customers.map(c=>c.showOptions = false)
        this.customers[index].showOptions = true
        this.customers = [...this.customers]
      },

      hideOptions() {
        this.showMore = false
        this.customers.forEach(c=>c.showOptions = false)
      },

      getCustomers(query) {
        let path = 'clients'
        if (query) path += `?query=${query}`

        this.$ax.get(path)
          .then(res => {
            this.customers = res.data.clients
          })
      },

      editCustomer(c) {
        this.hideOptions()
        this.showModal = true
        this.customerToEdit = c
      },

      deleteCustomer(e, id) {
        let c = confirm("Delete this customer?")
        if (c) {
          this.$ax.delete(`clients/${id}`)
            .then(res=>{
              this.hideOptions()
              this.showModal = false
              this.getCustomers();
            })
        }
      },

      goToCustomer(e, customer) {
        const { id, slug } = customer
        this.$router.push({ name: 'customer', params: { slug }})
      }
    }
  }
</script>

<style>
  .row-border {
    border-bottom-width: 0.20rem;
  }
</style>
