<template>
  <div>
    <form v-on:submit.prevent="save" style="width: 25rem;">
      <div class="flex flex-col mb-3">
        <label :class="style.form.label">CARRIER NAME</label>
        <input v-model="form.name" type="text" name="name" :class="style.form.input" :placeholder="placeholders.name">
      </div>

      <div class="flex flex-col mb-3">
        <label :class="style.form.label">CARRIER TYPE</label>
        <select v-model="form.carrier_type" name="carrier" id="" :class="style.form.input">
          <!-- <option value="nothing" selected>Select carrier type</option> -->
          <template v-for="type, index in types">
            <option v-bind:value="types[index]">{{type}}</option>
          </template>
        </select>
      </div>

      <div class="flex items-center">
        <button type="button" class="w-full mt-3" @click="closeForm" :class="style.btn.empty">Cancel</button>
        <button type="submit" class="w-full mt-3" :class="style.btn.fillSm">Save</button>
      </div>
      <div v-if="errorMessage" class="text-red-700 text-sm mt-2">{{errorMessage}}</div>

    </form>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        style: this.$store.state.style,
        errorMessage: null,
        types: ['ship', 'truck', 'train', 'air'],
        placeholders: {
          name: "Enter the carrier's name",
          type: "Choose carrier type"
        },
        form: {},
      }
    },
    props: {},
    components: {},
    methods: {
      closeForm() {
        this.$emit('statusForm', 'close form')
      },
      save() {
        // console.log('form data', this.form)
        this.$ax.post('carriers', {carrier: this.form})
          .then(res => {
            // console.log(res)
            // let data = {carrier_type: 'ship', name: 'example', id: 99}
            this.$emit('statusForm', 'save', res.data.carrier)
          })
      },
    }
  }
</script>
