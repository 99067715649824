<template>
  <form action="" style="width: 30rem;">
    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">REFERENCE</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="customer.code">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">COMPANY</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="customer.name">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">REPRESENTATIVE</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="customer.representative">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">PHONE</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="customer.phone_number">
    </div>

    <div class="flex flex-col mb-3">
      <label for="" :class="style.form.label">EMAIL</label>
      <input type="text" :class="style.form.input" class="bg-gray-100" v-model="customer.email">
    </div>

    <!-- BUTTONS -->

    <div v-if="needNavBack" class="flex mt-5">
      <!-- <button type="button" class="w-full text-gray-500 text-sm duration-300 hover:text-blue-700 outline-none">Back</button> -->
      <button type="button" class="w-full" :class="style.btn.fill" @click="saveCustomer('create')">
        Save and go back
      </button>
    </div>

    <button v-else-if="customerToEdit" type="button" class="w-full mt-5" :class="style.btn.fill" @click="saveCustomer('edit')">Save</button>

    <button v-else type="button" class="w-full mt-5" :class="style.btn.fill" @click="saveCustomer('create')">
      Save and email password
    </button>

    <div v-if="errorMessage" class="text-red-700 text-sm">{{errorMessage}}</div>

  </form>
</template>

<script>
  export default {
    data() {
      return {
        style: this.$store.state.style,
        customer: {
          code: null, name: null, representative: null, email: null, phone_number: null,
        },
        errorMessage: null,
        validations: ['code', 'name', 'representative', 'phone_number', 'email']
      }
    },

    props: {
      needNavBack: { type: Boolean, default: false },
      formType: { type: String, default: 'new' },
      customerToEdit: { type: Object }
    },

    mounted() {
      if (this.customerToEdit) this.customer = {...this.customerToEdit}
    },

    methods: {
      processData(res) {
        if (res.status==200) {
          // console.log(res.data.client)
          if (this.needNavBack) {
            this.$emit('navBackWithData', res.data.client)
          } else {
            this.$router.go()
          }
        } else {
          this.errorMessage = "Oops, something went wrong, please try again."
        }
      },

      saveCustomer(action) {
        const has_data = this.validations.every(k => this.customer[k])

        if (has_data) {
          if (action == 'create') {
            this.$ax.post('clients', {client: this.customer})
              .then(res => {
                this.processData(res)
              })
          } else if (action == 'edit') {
            this.$ax.put('clients/'+this.customer.id, {client: this.customer})
              .then(res => {
                this.processData(res)
              })
          }
        } else {
          this.errorMessage = "Please input all details and try again."
        }
      }
    }
  }
</script>

<style>

</style>
