<template>
  <div id="app">
    <dashboard />
  </div>
</template>

<script>
import Dashboard from './views/dashboard';

export default {
  data() {
    return {
    }
  },

  props: {
    user: String,
    logo: String,
    client: String
  },

  components: {
    Dashboard
  },

  mounted() {
    // this.$store.state.user = JSON.parse(this.user)
    // this.$store.state.logo = this.logo
    let data = { user: JSON.parse(this.user), logo: this.logo}
    if (this.client) data = Object.assign(data, {client: JSON.parse(this.client)} )
    this.$store.commit('loadData', data)
    this.$root.$emit('user-loaded')
  }
}
</script>

<style>
p {
  font-size: 2em;
  text-align: center;
}

svg {
  fill: #cdcdcd;
}
</style>
