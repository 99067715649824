<template>
  <div>
    <div class="bg-white px-5 pt-5 pb-12 relative border mb-5">
      <!-- <form action=""> -->
        <textarea v-model="content" name="" id="message" cols="30" rows="10"
          class="w-full text-sm text-gray-800 placeholder-gray-400 duration-300 outline-none h-24"
          :placeholder="placeholder">
        </textarea>
        <div class="pr-5 pb-2 absolute right-0 bottom-0">
          <button @click="sendMessage($event, shipment.id)" class="" :class="style.btn.fillSm">Send</button>
        </div>
      <!-- </form> -->
    </div>

    <div class="">
      <template v-for="m in inquiries"><message :message="m" /></template>
    </div>
  </div>
</template>

<script>
  import Message from './message';

  export default {
    data() {
      return {
        style: this.$store.state.style,
        placeholder: 'Type your message',
        content: '',
        audio: 'http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3',
      }
    },

    channels: {
      MessageChannel: {
        connected() { console.log('connected to websocket') },
        rejected() {},
        received(data) {
          console.log('from websocket', data)
          this.$emit('messageSent', data.message)
          const audio = new Audio(this.audio)
          audio.play()
        },
        disconnected() {}
      }
    },

    components: {
      Message
    },

    props: {
      inquiries: {type: Array},
      shipment: {type: Object}
    },

    mounted() {
      this.$cable.subscribe({
        channel: 'MessageChannel'
      });
    },

    methods: {
      sendMessage(e, shipment_id) {
        console.log('event', e)
        let msgField = document.getElementById("message")
        this.$ax.post(`shipments/${shipment_id}/messages`, { message: { content: this.content } })
          .then(res => {
            this.content = ''
            // this.$emit('messageSent', res.data.message)
          })
      },
    },
  }
</script>
