<template>
  <div>
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 10%;">HBL</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">SHIPMENT NO./PO</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 25%;">STATUS</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">LOADING PORT</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">FINAL DESTINATION</th>
          <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 15%;">ETA</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="s in shipments">
          <tr @click="goToShipment($event, s.slug)" :data-slug="s.slug" class="bg-white hover:bg-blue-100 duration-300 cursor-pointer text-sm">
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <!-- what is HBO?   -->
              <div>{{s.hbl}}</div>
              <div class="text-gray-500 text-sm" v-if="$can('manage', 'Shipment')">{{s.client.name}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{s.shipment_no}}</div>
              <div>{{s.pos||'-'}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{s.loading_port}} - {{s.final_destination}}</div>
              <div v-if="s.etd_date&&s.eta_to_final_date"
                class="text-xs">{{s.etd_date}} - {{s.eta_to_final_date}}</div>
              <div class="flex justify-start mt-3 items-start">
                <svg-building class="w-5 h-5 mr-2 flex-shrink-0"/>
                <div class="text-sm text-gray-500">{{s.carriers||'-'}}</div>
              </div>
              <div class="flex justify-start mt-1 items-start">
                <svg-container class="w-5 h-5 mr-2 flex-shrink-0"/>
                <div class="text-sm text-gray-500">{{s.container_nos.join(", ")||'-'}}</div>
              </div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{s.loading_port}}</div>
              <div class="text-gray-500 text-sm">{{s.etd_date}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              <div>{{s.final_destination}}</div>
              <div class="text-gray-500 text-sm">{{s.eta_to_final_date}}</div>
            </td>
            <td class="px-4 py-6 border-gray-100 row-border align-top">
              {{s.eta_to_final_date||'-'}}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!shipments.length" class="px-4 py-5 text-gray-500 text-base">No shipments</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },

    props: {
      shipments: { type: Array, default: () => [] }
    },

    methods: {
      goToShipment(e, slug) {
        this.$router.push({ name: 'shipment', params: { slug }})
      },
    }
  }
</script>
