<template>
    <div class="flex">
        <!-- <div v-if="$can('manage', 'Shipment')" class="flex justify-center items-center bg-white rounded-lg px-6 py-3 mr-4 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;"> -->
        <div class="flex justify-center items-center bg-white rounded-lg px-6 py-3 mr-4 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;">
            <div class="">
                <select class="text-darkgray-900" name="" id="" @change="checkCurrentStatus" v-model="tabCur">
                    <template v-for="c, index in currentStatus">
                        <option v-bind:key="index" :value="c">{{c}}</option>
                    </template>
                </select>
            </div>
        </div>
        <template v-for="m, index in modes">
            <div v-bind:key="index" class="flex justify-center items-center rounded-lg bg-white mr-2 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px; width: 48px;" :class="selectedModes.includes(m) ? 'bg-sky-500 duration-300' : ''" @click="filterMode($event, m)">
                <svg-filter-ocean v-if="m == 'Ocean'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'"/>
                <svg-filter-truck v-if="m == 'Truck'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'" />
                <svg-filter-air v-if="m == 'Air'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'" />
            </div>
        </template>

    </div>
</template>

<script>
export default {
    data() {
        return {
            currentStatus: [
                'All',
                'Quote Requested',
                'Pricing',
                'Quote Accepted',
                'Shipment Confirmed'
            ],

            modes: [
                'Ocean',
                'Truck',
                'Air'
            ],

            tabCur: 'Quote Requested',
            selectedModes: []
        }
    },

    methods: {
        filterMode(e, mode) {
            let idx = this.selectedModes.indexOf(mode)
            if (idx != -1) {
                this.selectedModes.splice(idx, 1)
                this.$emit('quote-mode', this.selectedModes)
                return
            }
            this.selectedModes.push(mode)
            this.$emit('quote-mode', this.selectedModes)
        },

        checkCurrentStatus(e) {
            this.$emit('quote-status', this.tabCur)
        }
    }

}
</script>

<style>

</style>