<template>
  <div style="width: 800px;">
      <div class="m-6 flex">
                <div class="mr-6">
                    <div class="mb-2 font-bold text-darkgray-900">Create a Package</div>
                    <div class="flex flex-col mb-4">
                            <label class="font-semibold" :class="style.form.label">PACKAGE DETAILS (00cartons = 00pallets / or wooden case )</label>
                            <input placeholder="" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="package_name" @change="createPackage">
                    </div>
                    <div class="flex justify-between mb-4">
                        <div class="flex flex-col mb-2 mr-2">
                            <label class="font-semibold" :class="style.form.label">LENGTH</label>
                            <input placeholder="(cm)" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="length" @change="createPackage">
                        </div>
                        <div class="flex flex-col mb-2">
                            <label class="font-semibold" :class="style.form.label">WIDTH</label>
                            <input placeholder="(cm)" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="width" @change="createPackage">
                        </div>
                    </div>
                    <div class="flex justify-between mb-4">
                        <div class="flex flex-col mb-2 mr-2">
                            <label class="font-semibold" :class="style.form.label">HEIGHT</label>
                            <input placeholder="(cm)" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="height" @change="createPackage">
                        </div>
                        <div class="flex flex-col mb-2">
                            <label class="font-semibold" :class="style.form.label">WEIGHT</label>
                            <input placeholder="(kg)" type="text" :class="style.form.input" class="package bg-gray-100 w-full" name="weight" @change="createPackage">
                        </div>
                    </div>
                    <div class="pb-2 flex justify-end">
                        <button class="" :class="style.btn.fillSm" @click="addPackage">Add Package</button>
                    </div>
                </div>
            <div>
                <div class="mb-2 font-bold text-darkgray-900">Packages to add</div>
                <div v-for="p, index in packages" class="flex justify-between mb-4">
                    <div>
                        <div>{{p.package_name}}</div>
                        <div>{{p.length}}x{{p.width}}x{{p.height}}cm/{{p.weight}}kg</div>
                    </div>
                    <div class="flex">
                        <div>
                            <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.1667 6.11667L13.8834 3.83334C13.5854 3.55342 13.1949 3.3928 12.7862 3.38205C12.3775 3.37129 11.9791 3.51114 11.6667 3.775L4.16674 11.275C3.89738 11.5466 3.72966 11.9027 3.69174 12.2833L3.33341 15.7583C3.32218 15.8804 3.33802 16.0034 3.37979 16.1187C3.42156 16.2339 3.48824 16.3385 3.57507 16.425C3.65294 16.5022 3.74529 16.5633 3.84682 16.6048C3.94835 16.6463 4.05707 16.6673 4.16674 16.6667H4.24174L7.71674 16.35C8.0974 16.3121 8.45343 16.1444 8.72507 15.875L16.2251 8.375C16.5162 8.06748 16.6735 7.65709 16.6626 7.23378C16.6516 6.81047 16.4733 6.40876 16.1667 6.11667ZM13.3334 8.9L11.1001 6.66667L12.7251 5L15.0001 7.275L13.3334 8.9Z" fill="#6D6D6D"/>
                            </svg> -->
                        </div>
                        <div class="cursor-pointer" @click="removePackage(index)">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 15.8333C5 16.2754 5.17559 16.6993 5.48815 17.0118C5.80071 17.3244 6.22464 17.5 6.66666 17.5H13.3333C13.7754 17.5 14.1993 17.3244 14.5118 17.0118C14.8244 16.6993 15 16.2754 15 15.8333V5.83333H5V15.8333ZM7.05 9.9L8.225 8.725L10 10.4917L11.7667 8.725L12.9417 9.9L11.175 11.6667L12.9417 13.4333L11.7667 14.6083L10 12.8417L8.23333 14.6083L7.05833 13.4333L8.825 11.6667L7.05 9.9ZM12.9167 3.33333L12.0833 2.5H7.91666L7.08333 3.33333H4.16666V5H15.8333V3.33333H12.9167Z" fill="#6D6D6D"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button v-if="this.packages.length" type="button" class="w-full mt-5 duration-300" :class="style.btn.fill" @click="savePackages">
        Save new packages
        </button>
  </div>
</template>

<script>
export default {
    data() {
        return {
            style: this.$store.state.style,
            packages: [],
            package: {

            },
        }
    },

    props: {
        shipment: { type: Object }
    },

    methods: {
        createPackage(e) {
            const { name, value } = e.target
            this.package[name] = value
        },

        addPackage() {
            if (Object.keys(this.package).length == 5) {
                this.packages.push(this.package)
                this.package = {}
                this.clearValues('package')
            } else {
                alert('Please fill in all the package information')
            }
        },

        removePackage(index) {
            this.packages.splice(index, 1)
        },

        clearValues(classname) {
            let elements = document.querySelectorAll(`.${classname}`)
            elements.forEach(element => {
                element.value = ""
            })
        },

        processData(res) {
            if (res.status == 200) {
                this.$emit('add-packages', res.data.parcels)
            }
        },

        savePackages() {
            console.log(this.packages)
            this.$ax.post('parcels', {shipment: {id: this.shipment.id, packages: this.packages}})
            .then(res => {
                this.processData(res)
            })
        },
    }

}
</script>

<style>

</style>