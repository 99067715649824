<template>
  <div>
    <!-- TABS -->
    <div class="flex justify-start mb-5">
      <div v-for="tab, index in tabs"
        @click="changeTab(index)"
        class="mr-5 cursor-pointer duration-300 border-b-2"
        :class="index==tabCur?style.tabActive:style.tab">{{tab}}</div>
    </div>

    <!-- Inquiries/Documents -->
    <inquiries @messageSent="appendMessage" :inquiries="inquiries" :shipment="shipment" v-if="tabCur==0" />
    <documents @updateDoc="updateDocRefresh" :documents="documents" :shipment="shipment" v-if="tabCur==1" />
    <quotes v-if="tabCur == 2" :shipment="shipment" :quotes="quotes"/>
    <packages v-if="tabCur == 3" :shipment="shipment" :packages="packages"/>
  </div>
</template>

<script>
  import Inquiries from './inquiries';
  import Documents from './documents';
  import Quotes from './quotes'
  import Packages from './packages'

  export default {
    data() {
      return {
        tabCur: 0,
        tabs: ['INQUIRIES', 'DOCUMENTS', "QUOTES", "PACKAGES"],
        style: {
          tabActive: 'border-gray-800',
          tab: 'text-gray-400 hover:text-gray-600 border-transparent'
        }
      }
    },

    components: {
      Inquiries, Documents, Quotes, Packages
    },

    props: {
      inquiries:  {type: Array},
      documents: {type: Array},
      shipment: {type: Object},
      quotes: {type: Array},
      packages: { type: Array }
    },

    methods: {
      changeTab(index) {
        if (this.tabCur!=index) {
          this.tabCur = index
        }
      },

      updateDocRefresh(action, value) {
        console.log('on activities.vue documents', value)
        if (action == 'delete doc') {
          this.$emit('updateValue', 'remove document', value)
        } else if (action == 'append doc') {
          this.$emit('updateValue', 'append document', value)
        }
      },

      appendMessage(message) {
        console.log('message =>', message)
        this.$emit('updateValue', 'append message', message)
      },
    }
  }
</script>
