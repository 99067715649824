<template>
  <div>
      <div class="flex justify-start items-center mb-5">
      <!-- <button class="mr-5" :class="style.btn.ghostSm">Download all</button> -->
        <button @click="toggleModal" class="mr-4"
            :class="style.btn.ghostSm" v-if="$can('manage', 'Shipment')">New Package
        </button>
        <download-excel :data="packages" name="package_list.xls" :fields="json_fields">
            <div class="font-bold text-sky-500 text-sm cursor-pointer">Download as Excel</div>
        </download-excel>
      </div>
      <div class="grid grid-cols-2 gap-4">
           <div v-for="p, index in packages" class="bg-white rounded-lg p-4 flex flex-col justify-between" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); width: 400px; height: 160px; ">
                <div class="flex justify-between">
                    <div class="text-sm font-bold text-darkgray-900">{{p.ref}}</div>
                    <v-dropdown v-if="$can('manage','Shipment')" placement="right">
                    <template v-slot:button>
                    <div class="cursor-pointer">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0782 6.60408C12.0771 6.60408 12.8878 5.79338 12.8878 4.79447C12.8878 3.79557 12.0771 2.98486 11.0782 2.98486C10.0793 2.98486 9.26855 3.79557 9.26855 4.79447C9.26855 5.79338 10.0793 6.60408 11.0782 6.60408ZM11.0782 9.31849C10.0793 9.31849 9.26855 10.1292 9.26855 11.1281C9.26855 12.127 10.0793 12.9377 11.0782 12.9377C12.0771 12.9377 12.8878 12.127 12.8878 11.1281C12.8878 10.1292 12.0771 9.31849 11.0782 9.31849ZM9.26855 17.4617C9.26855 16.4628 10.0793 15.6521 11.0782 15.6521C12.0771 15.6521 12.8878 16.4628 12.8878 17.4617C12.8878 18.4606 12.0771 19.2713 11.0782 19.2713C10.0793 19.2713 9.26855 18.4606 9.26855 17.4617Z" fill="#343C44"/>
                        </svg>
                    </div>
                    </template>
                    <template v-slot:content>
                        <!-- <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-sky-500 hover:text-white"  >Edit</a>

                        <hr /> -->

                        <a class="cursor-pointer flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-500 hover:text-white" @click="destroyPackage(index)">Delete</a>
                    </template>
                    </v-dropdown>
                </div>
                <div>
                    <div class="font-bold">{{p.package_name}}</div>
                    <div class="flex justify-between">
                        <div>
                            <div class="flex">
                                <div class="mr-2">
                                    <div class="text-sm text-gray-500">Length:</div>
                                    <div class="text-sm">{{p.length}} cm</div>
                                </div>
                                <div class="mr-2">
                                    <div class="text-sm text-gray-500">Width:</div>
                                    <div class="text-sm">{{p.width}} cm</div>
                                </div>
                                <div class="mr-2">
                                    <div class="text-sm text-gray-500">Height:</div>
                                    <div class="text-sm">{{p.height}} cm</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-sm text-gray-500">Weight:</div>
                            <div class="text-sm">{{p.weight}} kg</div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    <div v-if="packages.length == 0" class="px-4 py-5 text-gray-500 text-base text-center">There were no packages added in this shipment.</div>
    <form-modal v-if="showModal" @toggleModal="toggleModal">
        <package-form :shipment="shipment" @add-packages="getPackages($event)" />
    </form-modal>

  </div>
</template>

<script>
import VDropdown from '../../components/v-dropdown'
import FormModal from '../../components/form_modal'
import PackageForm from './package_form.vue'

export default {
    data() {
        return {
            style: this.$store.state.style,
            showModal: false,
            json_fields: {
                "Ref": "ref",
                "Length (cm)": "length",
                "Width (cm)": "width",
                "Height (cm)": "height",
                "Weight (kg)": "weight",
                "Tracking No": "tracking_no",
                "Description": "description",
                
            },
        }
    },

    computed: {
    },

    props: {
        packages: { type: Array, default: [] },
        shipment: {type: Object}
    },

    components: {
        VDropdown, FormModal, PackageForm
    },

    methods: {
        destroyPackage(i) {
            let c = confirm('Are you sure you want to delete this parcel?')
            const pack = this.packages[i]
            if (c) {
                this.packages.splice(i, 1)
                this.$ax.delete(`parcels/${pack.id}`)
                .then(res => {
                    console.log(res)
                })
            }
        },
        toggleModal(showModal) {
            this.showModal = showModal
        },

        getPackages(event) {
            this.packages = event
            this.showModal = false
        }
    }

}
</script>

<style>

</style>