<template>
  <div>
    <div class="text-xl mb-5 pt-3">New Staff</div>
    <staff-form :needNavBack="needNavBack" @navBackWithData="navBackWithData" />
  </div>
</template>

<script>
  import StaffForm from './staff_form';

  export default {
    data() {
      return {
      }
    },

    props: {
      needNavBack: { type: Boolean, default: false }
    },

    components: {
      StaffForm
    },

    methods: {
      navBackWithData(data) {
        this.$emit('navBackWithData', data)
      }
    }
  }
</script>