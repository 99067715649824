<template>
    <div class="relative">
        <v-dropdown placement="right">
        <template v-slot:button>
        <div class="flex items-center" v-show="active">
            <div>
                <p class="text-right text-xs text-black font-bold">{{user.email}}</p>
                <p class="text-right text-xs">{{user.role}}</p>
            </div>
            <div class="bg-gray-300 rounded-full ml-4 flex items-center justify-center text-white font-bold" style="width: 45px; height: 45px">
                {{userInitial}}
            </div>
        </div>
        </template>
        <template v-slot:content>
            <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-sky-500 hover:text-white" href="https://geo4logistics.com/#contact" target="_blank">Help</a>

            <hr />

            <a class="cursor-pointer flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-500 hover:text-white" @click="logout">Logout</a>
        </template>
        </v-dropdown>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import VDropdown from '../components/v-dropdown.vue'

export default {
    data() {
        return {
            active: true
        }
    },
    components: {
        VDropdown
    },

    computed: {
        ...mapState(['user']),
        userInitial() {
            return this.user.first_name[0] || 'G'
        }
    },

    methods: {
        logout() {
        const path = this.$store.state.path[this.$store.state.env]
        this.$ax.delete('users/sign_out')
          .then(res => {
            // console.log('signout res', res);
            if (res.status == 200) {
              this.$router.push('/users/sign_in')
            }
          });
      },
      toggleActive(e) {
          this.active = !this.active
      }

    }
}
</script>
<style>


</style>