<template>
  <div class="w-full">
    <form class="w-full">
      <div class="w-full lg:flex">

        <div class="sm:w-full lg:w-1/2">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 sm:text-lg lg:text-xs font-bold mb-2" for="grid-first-name">
                First Name *
              </label>
              <input :class="inputFieldStyle" class="mb-3 sm:text-2xl lg:text-base" id="grid-first-name" type="text" :placeholder="form.firstName.placeholder" v-model="form.firstName.value">
              <!-- for red border change grey border to border-red-500 and remove focus:border-gray -->
              <!-- <p class="text-red-500 sm:text-lg lg:text-xs italic">Please fill out this field.</p> -->
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 sm:text-lg lg:text-xs font-bold mb-2" for="grid-last-name">
                Last Name
              </label>
              <input :class="inputFieldStyle" class="sm:text-2xl lg:text-base" id="grid-last-name" type="text" :placeholder="form.lastName.placeholder" v-model="form.lastName.value">
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 sm:text-lg lg:text-xs font-bold mb-2" for="grid-password">
                Email *
              </label>
              <input :class="inputFieldStyle" class="mb-3 sm:text-2xl lg:text-base" id="email" type="email" v-model.trim="form.email.value">
              <!-- <p class="text-gray-600 sm:text-lg lg:text-xs italic">Some tips - as long as needed</p> -->
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 sm:text-lg lg:text-xs font-bold mb-2" for="grid-password">
                Company
              </label>
              <input :class="inputFieldStyle" class="sm:text-2xl lg:text-base" id="company" type="text" v-model="form.company.value">
              <!-- <p class="text-gray-600 sm:text-lg lg:text-xs italic">Some tips - as long as needed</p> -->
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mb-6 sm:w-full lg:w-1/2">
          <div class="w-full lg:pl-6 flex flex-col">
            <label class="block uppercase tracking-wide text-gray-700 sm:text-lg lg:text-xs font-bold mb-2" for="grid-password">
              Message *
            </label>
            <textarea :class="inputFieldStyle" class="sm:text-2xl lg:text-base pl-3 no-resize sm:h-40 lg:h-full resize-none" id="message" v-model="form.message.value"></textarea>
            <!-- <p class="text-gray-600 sm:text-lg lg:text-xs italic">Re-size can be disabled by set by resize-none / resize-y / resize-x / resize</p> -->
          </div>
        </div>

      </div>

      <div class="md:flex md:items-center">
        <div class="md:w-1/3">
          <button class="mt-3 flex items-center sm:text-lg lg:text-base" :class="btnStyle" type="button" @click="submitForm" :disabled="formSubmitting">
            {{formSubmitting?'Sending':'Send'}}
            <div class="ml-2" v-if="formSubmitting">
              <svg width="25" height="25" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                  <g fill="none" fill-rule="evenodd">
                      <g transform="translate(1 1)" stroke-width="2">
                          <circle cx="5" cy="50" r="5">
                              <animate attributeName="cy"
                                   begin="0s" dur="2.2s"
                                   values="50;5;50;50"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                              <animate attributeName="cx"
                                   begin="0s" dur="2.2s"
                                   values="5;27;49;5"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                          </circle>
                          <circle cx="27" cy="5" r="5">
                              <animate attributeName="cy"
                                   begin="0s" dur="2.2s"
                                   from="5" to="5"
                                   values="5;50;50;5"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                              <animate attributeName="cx"
                                   begin="0s" dur="2.2s"
                                   from="27" to="27"
                                   values="27;49;5;27"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                          </circle>
                          <circle cx="49" cy="50" r="5">
                              <animate attributeName="cy"
                                   begin="0s" dur="2.2s"
                                   values="50;50;5;50"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                              <animate attributeName="cx"
                                   from="49" to="49"
                                   begin="0s" dur="2.2s"
                                   values="49;5;27;49"
                                   calcMode="linear"
                                   repeatCount="indefinite" />
                          </circle>
                      </g>
                  </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="md:w-2/3"></div>
      </div>
    </form>

    <div class="fixed inset-0" :class="[modal.visible?'fixed':'hidden']" style="background: rgba(0,0,0,0.3);">
      <!-- <div class="h-screen w-full bg-black opacity-25"></div> -->
      <div class="h-screen w-full flex flex-col items-center justify-center bg-teal-lightest font-sans">
        <div v-if="modal.visible" @click.self="hideModal" class="h-screen w-full absolute flex items-center justify-center bg-modal">
          <div class="bg-white rounded shadow sm:p-12 lg:p-8 m-4 sm:max-w-lg lg:max-w-xs max-h-full text-center overflow-y-scroll">
            <div class="mb-4">
              <h1 class="sm:text-3xl lg:text-xl font-bold">{{modal.title}}</h1>
            </div>
            <div class="mb-8">
              <p class="sm:text-2xl lg:text-xl">{{modal.msg}}</p>
            </div>
            <div class="flex justify-center">
              <!-- flex-no-shrink text-white py-2 px-4 rounded bg-teal hover:bg-teal-dark -->
              <button class="sm:text-lg lg:text-base" :class="btnStyle" @click="hideModal">Got it!</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    data: function() {
      return {
        modal: { visible: false },
        formSubmitting: false,
        formSubmitted: false,
        form: {
          firstName: {label: 'FIRST NAME', value: '', placeholder: 'Jane', required: true, errorMsg: '' },
          lastName: {label: 'LAST NAME', value: '', placeholder: 'Doe', errorMsg: '' },
          email: {label: 'EMAIL', value: '', required: true, errorMsg: '' },
          company: {label: 'COMPANY', value: '', errorMsg: '' },
          message: {label: 'MESSAGE', value: '', required: true, errorMsg: '' }
        },
        inputFieldStyle: `appearance-none block w-full bg-white text-gray-700 border border-gray-200
                          rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500
                          transition duration-500`,
        btnStyle: "border inline-block py-1 px-4 text-blue-700 border-blue-700 hover:text-white hover:bg-blue-700 duration-300"
      }
    },

    methods: {
      submitForm() {
        // console.log('submitting', this.form)
        const form = this.form
        const data = {}
        const keys = Object.keys(form)
        keys.forEach(k=> data[k] = form[k].value)
        // console.log(data)
        Object.keys(data).forEach(k => {
          if (!data[k]) delete data[k]
        })
        // console.log('data after', data)
        if (!data.firstName || !data.email || !data.message) {
          this.modal = {
            visible: true,
            title: 'Oops, something went wrong..',
            msg: 'Please tell us your name, email address and your message :)'
          }
        } else {
          this.formSubmitting = true
          // const token = this.$store.state.csrf
          // const path = this.$store.state.path+'inquiry?'
          // const params = Object.keys(data).map(k=>k+'='+data[k]).join('&')
          if (Object.keys(data).length) {
            // this.$ax.get('inquiry?' + params).then(res => {
            this.$ax.post('inquiry', { inquiry: data }).then(res => {
            // axios.get(path+params).then(res => {
              console.log(res)
              if (res.status==200) {
                const modal = { visible: true, title: res.data.title, msg: res.data.msg }
                this.modal = modal
                this.formSubmitting = false
                this.formSubmitted = true
              }
            })
          }
        }
      },
      hideModal() {
        this.modal.visible = false
        if (this.formSubmitted) {
          const form = this.form
          Object.keys(form).forEach(k => form[k].value = '')
          this.form = form
          this.formSubmitted = false
        }
      }
    },

    components: {
    }
  }
</script>

<style scoped>

</style>
