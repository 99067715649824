<template>
  <div class="relative">
    <button class="z-10 relative flex items-center focus:outline-none select-none" @click="toggleModal" ref="mydropdown">
      <slot name="button"></slot>
    </button>

    <!-- to close when clicked on space around it-->
    <button class="fixed inset-0 h-full w-full cursor-default focus:outline-none" v-if="open" @click="toggleModal" tabindex="-1"></button>

    <!--dropdown menu-->
    <transition enter-active-class="transition-all duration-200 ease-out" leave-active-class="transition-all duration-750 ease-in" enter-class="opacity-0 scale-75" enter-to-class="opacity-100 scale-100" leave-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-75">
      <div class="absolute border w-56 rounded-lg py-1 px-2 text-sm mt-4 bg-white z-10" :class="placement === 'right' ? 'right-0' : 'left-0'" v-if="open" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%);">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        open: false,
      };
    },
    props: {
      placement: {
        type: String,
        default: "right",
        validator: (value) => ["right", "left"].indexOf(value) !== -1,
      },
    },

    created() {
      window.addEventListener('click', this.close)
    },

    beforeDestroy() {
      window.removeEventListener('click', this.close)
    },
    mounted() {
    },

    methods: {
      toggleModal() {
        this.open = !this.open
      },

      close(e) {
        if (!this.$el.contains(e.target)) {
        this.open = false
        }
      }
    }
  };
</script>
