<template>
  <div>
    <div class="text-xl mb-5 pt-3">{{title}}</div>

    <shipment-form :shipmentToEdit="shipmentToEdit" :btnText="btnText" :requestOnly="isRequestForm"/>
  </div>
</template>

<script>
  import ShipmentForm from './components/form';

  export default {
    data() {
      return {
      }
    },

    props: {
      shipmentToEdit: { type: Object, required: true },
      title: { type: String, default: 'Edit Shipment'},
      btnText: { type: String, default: 'Update'},
      isRequestForm: { type: Boolean, default: false }
    },

    components: {
      ShipmentForm
    }
  }
</script>
