<template>
  <div>
    <div class="text-xl mb-5 pt-3">Edit Staff</div>
    <staff-form :staffToEdit="staff" />
  </div>
</template>

<script>
  import StaffForm from './components/form';

  export default {
    data() {
      return {
      }
    },

    props: {
      staff: { type: Object, required: true },
    },

    components: {
      StaffForm
    }
  }
</script>
