<template>
  <div class="border-b pb-6 mb-10">
    <div class="flex justify-between items-center mb-5">
      <div class="flex">
        <div class="font-bold mr-2">SHIPMENT DETAILS</div>
        <div class="flex justify-center items-center rounded-sm border cursor-pointer mr-2" style="width: 23px; height: 23px" @click="toggleDetails">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.97597 2.60755L10.9676 2.60755C11.141 2.60727 11.3107 2.65723 11.4562 2.75138C11.6018 2.84553 11.7169 2.97983 11.7877 3.13804C11.8586 3.29625 11.882 3.47159 11.8553 3.64286C11.8286 3.81413 11.7528 3.97398 11.6371 4.10309L7.19005 10.0365C7.10632 10.1472 6.99806 10.2371 6.87376 10.2989C6.74947 10.3607 6.61253 10.3929 6.4737 10.3929C6.33487 10.3929 6.19793 10.3607 6.07363 10.2989C5.94934 10.2371 5.84108 10.1472 5.75734 10.0365L1.25989 4.0408C1.11792 3.85074 1.05718 3.61211 1.091 3.37731C1.12482 3.14251 1.25043 2.93073 1.44026 2.78846C1.60222 2.66496 1.7891 2.60809 1.97651 2.60809L1.97597 2.60755Z" fill="black"/>
          </svg>
        </div>
      </div>

      <div class="relative text-right" v-if="$can('manage', 'Shipment')">
        <button class="border rounded w-6 h-6 duration-300 hover:bg-gray-400 cursor-pointer" @click="toggleOptions">...</button>

        <div class="fixed inset-0 z-10" v-if="showMore" @click="toggleOptions"></div>

        <div class="absolute bg-white border top-0 z-50" style="right: 1.7rem;" v-if="showMore">
          <div class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200"
            @click="editShipment">Edit</div>
          <div class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200"
            @click="deleteShipment">Delete</div>
        </div>
      </div>
    </div>

    <!-- Shipment PO's -->
    <div class="px-4 py-2 bg-white flex flex-wrap justify-start items-end mb-5">
      <!-- <p class="text-gray-500 mr-5 text-xs">PO</p> -->
      <!-- <p class="text-base">{{ shipment.pos.join(' / ') }}</p> -->
      <div v-for="po, index in shipment.pos" class="bg-gray-200 px-6 py-1 mr-2 mb-2 rounded-full">
        {{po}}
      </div>
    </div>

    <!-- Shipment Details -->
    <div v-if="showDetails">
      <div class="flex border-b pb-6" style="width: 100%;">
        <div style="width: 50%;">
          <table>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">MBL</td>
              <td class="w-2/3 px-4 py-2">{{shipment.mbl||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">HBL</td>
              <td class="w-2/3 px-4 py-2">{{shipment.hbl||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">CONTAINER</td>
              <td class="w-2/3 px-4 py-2">{{shipment.container_nos.join(", ")||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">SHIPMENT TYPE</td>
              <td class="w-2/3 px-4 py-2">{{shipment.shipment_type || '-' }}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">INCOTERM</td>
              <td class="w-2/3 px-4 py-2">{{shipment.incoterm || '-' }}</td>
            </tr>
          </table>
        </div>
        <div style="width: 50%;">
          <table>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">VESSEL</td>
              <td class="w-2/3 px-4 py-2">{{shipment.vessel||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">WEIGHT</td>
              <td class="w-2/3 px-4 py-2">{{shipment.weight||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">CBM</td>
              <td class="w-2/3 px-4 py-2">{{shipment.cbm||'-'}}</td>
            </tr>
            <tr>
              <td class="text-gray-500 text-xs w-1/3 px-4 py-2">CONTAINER SIZE</td>
              <td class="w-2/3 px-4 py-2">{{shipment.container_size || '-'}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex border-b py-6" style="width: 100%;">
        <div style="width: 50%;">
          <table>
            <tr>
              <div class="text-gray-500 text-xs px-4">LOADING PORT</div>
              <div class="px-4 pb-2">{{shipment.loading_port||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ORIGIN LOCATION</div>
              <div class="px-4 pb-2">{{shipment.origin_location || '-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ETC</div>
              <div class="px-4 pb-2">{{shipment.etc_date||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ETD</div>
              <div class="px-4 pb-2">{{shipment.etd_date||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ETA TO PORT</div>
              <div class="px-4 pb-2">{{shipment.eta_to_port_date||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ETA TO FINAL DESTINATION</div>
              <div class="px-4 pb-2">{{shipment.eta_to_final_date||'-'}}</div>
            </tr>
          </table>
        </div>
        <div style="width: 50%;">
          <table>
            <tr>
              <div class="text-gray-500 text-xs px-4">DISCHARGE PORT</div>
              <div class="px-4 pb-2">{{shipment.final_destination||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">FINAL DESTINATION</div>
              <div class="px-4 pb-2">{{shipment.destination_location||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">ATA DATE</div>
              <div class="px-4 pb-2">{{shipment.ata_date||'-'}}</div>
            </tr>
            <tr>
              <div class="text-gray-500 text-xs px-4">FINAL DELIVERY DATE</div>
              <div class="px-4 pb-2">{{shipment.final_delivery_date||'-'}}</div>
            </tr>
          </table>
        </div>
      </div>

      <div class="font-bold mr-2 pt-6">CARGO DETAILS</div>
      <div class="flex pt-6" style="width: 100%;">
        <div style="width: 50%;">
          <table>
            <tr>
              <div class="text-gray-500 text-xs px-4">TOTAL WEIGHT</div>
              <div class="px-4 pb-2">{{shipment.weight || ' - '}} kg</div>
            </tr>
            <!-- <tr>
              <div class="text-gray-500 text-xs px-4">PACKAGES</div>
              <div v-if="shipment.packages" v-for="p, index in shipment.packages">
                <div class="px-4 font-semibold">{{p.package_name}}</div>
                <div class="px-4 pb-2">{{p.length}}x{{p.width}}x{{p.height}}cm/{{p.weight}}kg</div>
              </div>
            </tr> -->
          </table>
        </div>
        <div style="width: 50%;">
          <table>
            <tr>
              <div class="text-gray-500 text-xs px-4">TOTAL VOLUME</div>
              <div class="px-4 pb-2">{{shipment.cbm || ' - '}} cbm</div>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <form-modal v-if="showModal" @toggleModal="toggleModal">
      <shipment-edit :shipmentToEdit="{...shipment}" />
    </form-modal>
  </div>
</template>

<script>
  import FormModal from '../../components/form_modal'
  import ShipmentEdit from '../edit'

  export default {
    data() {
      return {
        showModal: false,
        showMore: false,
        showDetails: false
      }
    },

    props: {
      labelLeft: { type: String },
      detailLeft: { type: String },
      labelRight: { type: String },
      detailRight: { type: String },
      shipment: { type: Object },
    },

    components: {
      ShipmentEdit, FormModal
    },

    methods: {
      deleteShipment() {
        // console.log('deleting shipment')
        let c = confirm("Delete this shipment?")
        if (c) {
          this.$ax.delete(`shipments/${this.shipment.id}`)
            .then(res => {
              this.$router.push({name: 'shipments'})
            })
        }
      },
      editShipment() {
        this.showModal = true
      },
      toggleModal(showModal) {
        this.showModal = showModal
      },
      toggleOptions() {
        this.showMore = !this.showMore
      },
      toggleDetails() {
        this.showDetails = !this.showDetails
      },
    }
  }
</script>
