<template>
  <!-- <div class="antialiased bg-gray-200 text-gray-900 font-sans overflow-x-hidden"> -->
  <div class="w-screen h-screen fixed inset-0 z-50 px-4 min-h-screen md:flex md:items-center md:justify-center">
    <div class="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
    <div class="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
      <div class="md:flex items-center">
        <div>
          <svg width="40" height="40" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(2 1)" stroke="#5287B8" stroke-width="1.5">
                    <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="1;0;0;0;0;0;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;1;0;0;0;0;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;1;0;0;0;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;0;1;0;0;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;0;0;1;0;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;0;0;0;1;0;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;0;0;0;0;1;0" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                    <circle cx="27" cy="5" r="5" fill-opacity="0" fill="#5287B8">
                        <animate attributeName="fill-opacity"
                             begin="0s" dur="1.3s"
                             values="0;0;0;0;0;0;0;1" calcMode="linear"
                             repeatCount="indefinite" />
                    </circle>
                </g>
            </g>
        </svg>
        </div>
        <div class="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
          <p class="font-bold text-sm">Submitting Request</p>
          <p class="text-sm text-gray-700 mt-1">Please wait until our team handles your request
          </p>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
</template>

<script>
export default {

}
</script>

<style>

</style>