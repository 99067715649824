<template>
  <div class="mb-8">
    <div class="flex items-end mb-1" :class="[isSelf?'flex-row-reverse':'']">
      <div class="text-sm" :class="[isSelf?'ml-5':'mr-5']">{{name}}</div>
      <div class="text-gray-400 text-xs">{{message.created_at}}</div>
    </div>

    <div :class="[isSelf?'justify-end':'justify-start']" class="inline-block flex">
      <div class="py-3 px-5 max-w-2xl" style="white-space: pre-line;"
        :class="[isSelf?'bg-gray-300 message-self':'bg-blue-200 message-other']">{{message.content}}</div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
      }
    },

    props: {
      message: { type: Object },
    },

    computed: {
      ...mapState(['user']),
      isSelf() {
        return (this.message.user.id == this.user.id)
      },
      name() {
        const adminRoles = ['admin', 'staff']
        const clientRoles = ['client', 'representative']
        if (this.message.user.id == this.user.id) {
          if (this.user.role == 'staff' || this.user.role == 'admin') {
            return this.message.user.first_name
          } else {
            return 'You'
          }
        } else {
          if (clientRoles.includes(this.user.role)) {
            // return 'Customer Service'
            if (adminRoles.includes(this.message.user.role)) {
              return this.message.user.first_name + " (Customer Service)"
            } else {
              return this.message.user.first_name
            }
          } else {
            if (adminRoles.includes(this.message.user.role)) {
              return this.message.user.first_name + " (Customer Service)"
            } else {
              return this.message.user.first_name + " (Client)"
            }
          }
        }
      }
    }
  }
</script>

<style>
  .message-other {
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }

  .message-self {
    border-top-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }
</style>
