<template>
  <div class="bg-white relative">
    <div class="flex items-center mb-10">
      <div class="text-xl">Shipping Status</div>
      <div v-if="shipment.is_completed" class="bg-blue-600 rounded-full text-white px-3 py-1 ml-3">Completed</div>
    </div>
    <div class="" v-if="shipment.is_confirmed">
      <template v-for="status, index in statuses">
        <status @statusForm="appendStatus" :status="status" :index="index" :shipment="shipment" :last=false />
      </template>

      <status v-if="statuses[statuses.length - 1]"
        @statusForm="appendStatus" :status="statuses[statuses.length - 1]"
        :shipment="shipment" :last=true />

      <!-- <status v-if="isForm">
        <status-form @statusForm="appendStatus" v-if="isForm" :shipment="shipment" :new=true />
      </status> -->
    </div>

    <status-form @statusForm="appendStatus" v-if="isForm && shipment.is_confirmed" :shipment="shipment" :isNew=true />

    <div class="flex justify-end items-center" v-if="$can('manage', 'Shipment') && shipment.is_confirmed">
      <button class="mr-5" :class="disableAddNext?style.btn.disabled:style.btn.ghost"
        @click="addNext" :disabled="disableAddNext">Add {{!statuses.length ? 'status' : 'next'}}</button>
      <button :class="style.btn.ghost" @click="completeShipment">
        {{shipment.is_completed?'Continue shipping':'Mark as completed'}}</button>
    </div>
    <status-tracker v-if="!shipment.is_confirmed" :shipment="shipment"/>
  </div>
</template>

<script>
  import StatusTracker from '../../components/status_tracker.vue';
  import Status from './shipping_status';
  import StatusForm from './status_form';

  export default {
    data() {
      return {
        startLocation: { name: 'Shanghai', location: 'Port 123' },
        style: this.$store.state.style,
        createNew: false,
        isForm: false,
      }
    },

    computed: {
      disableAddNext() {
        return this.createNew || this.shipment.is_completed
      }
    },

    props: {
      statuses: { type: Array },
      shipment: { type: Object },
    },

    components: {
      Status, StatusForm, StatusTracker
    },

    methods: {
      addNext() {
        // const form = { isForm: true }
        // this.statuses.push(form)
        this.isForm = true
        this.createNew = true
      },
      appendStatus(action, value) {
        console.log('appendStatus', action, value)
        this.isForm = false
        this.createNew = false
        if (action == 'append status') {
          this.$emit('updateValue', 'append shipping status', value)
        } else if (action == 'replace status') {
          this.$emit('updateValue', 'replace shipping status', value)
        } else if (action == 'remove status') {
          this.$emit('updateValue', 'remove shipping status', value)
        }
      },

      completeShipment() {
        const { is_completed } = this.shipment
        let c = is_completed ? confirm("Comfirm changing the shipment back to in progress?") : confirm("Comfirm marking the shipment as completed?")
        if (c) {
          this.$ax.put('shipments/'+this.shipment.id, {shipment: {is_completed: !this.shipment.is_completed}})
            .then(res => {
              console.log(res)
              if (res.status==200) {
                this.$emit('reloadData')
              }
            })
        }
      }
    }
  }
</script>

<style>

</style>
