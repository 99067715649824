<template>
  <div class="flex w-screen">
    <side-nav class="w-24 h-screen py-10 sticky top-0 z-50" />
    <div class="min-h-screen" style="width: calc(100vw);">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import ShipmentsIndex from './shipments/index';
  import ShipmentShow from './shipments/show';
  import ShipmentCreate from './shipments/create';
  import CustomersIndex from './customers/index';
  import CustomerCreate from './customers/create';
  import CustomerShow from './customers/show';
  import QuotesIndex from './quotes/index';
  import QuotesCreate from './quotes/create';
  import StaffIndex from './staff/index';


  const routes = [
    { path: '/dashboard/shipments', name: 'shipments', component: ShipmentsIndex },
    { path: '/dashboard/shipments/new', name: 'shipment-new', component: ShipmentCreate },
    { path: '/dashboard/shipments/:slug', name: 'shipment', component: ShipmentShow },
    { path: '/dashboard/customers', name: 'customers', component: CustomersIndex },
    { path: '/dashboard/customers/new', name: 'customer-new', component: CustomerCreate },
    { path: '/dashboard/customers/:slug', name: 'customer', component: CustomerShow },
    { path: '/dashboard/quotes', name: 'quotes', component: QuotesIndex },
    { path: '/dashboard/quotes/new', name: 'quotes-new', component: QuotesCreate },
    { path: '/dashboard/staff', name: 'staff', component: StaffIndex },
    { path: '/dashboard*', redirect: '/dashboard/shipments' },
    { path: '/users/sign_in', beforeEnter(to, from, next) {
        window.location = "/dashboard"
      }
    },
  ]

  export default {
    data: function() {
      return {
        path: this.$store.state.path[this.$store.state.env]
      }
    },
    components: {
      ShipmentsIndex, ShipmentShow, ShipmentCreate, CustomersIndex, CustomerCreate, CustomerShow, QuotesCreate
    },

    computed: {
    },

    mounted() {
      if (!this.$router.options.isLoaded) {
        this.$router.addRoutes(routes)
        this.$router.options.isLoaded = true
      }
    }
  }
</script>

<style>

</style>
