<template>
  <div class="bg-gray-100 p-10 min-h-full">
    <page-title title="Shipments" :btnText="btnText" btn="shipmentCreate" />
    <div class="flex justify-between mb-10">
      <div>
        <!-- <tabs @clicked="onClickTab" :tabCur="tabCur" /> -->
        <filters class="mt-2" @current-page="clickTab($event)" @select-mode="selectShipmentMode($event)" />
      </div>
      <div class="flex">
        <search-bar :placeholder="'Search for PO, TAGS, HBL, MBL'+[$can('manage','Shipment')?', Container no. or customer':' or Container no']" @search="searchShipment" />
      </div>
    </div>

    <shipment-list v-if="tabCur == 0" :shipments="filteredShipments[0]" />
    <shipment-list v-if="tabCur == 1" :shipments="filteredShipments[1]" />
    <shipment-requests v-if="tabCur == 2" :shipments="filteredShipments[2]" />
    <!-- <shipment-list v-if="tabCur == 0" :shipments="shipments[0]" />
    <shipment-list v-if="tabCur == 1" :shipments="shipments[1]" />
    <shipment-requests v-if="tabCur == 2" :shipments="shipments[2]" /> -->

  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Tabs from './components/tabs'
  import ShipmentRequests from './components/shipment_requests'
  import ShipmentList from './components/shipment_list'
  import Filters from './components/filters'
  // import Filter_checkbox from './components/filter_checkbox.vue'
  // import axios from 'axios';

  export default {
    data() {
      return {
        // searchQuery: null,
        style: this.$store.state.style,
        shipments: [],
        tabCur: 0,
        btnText: null,
        currentSearchKeyword: '',
        shipmentTypes: []
      }
    },

    computed: {
      ...mapState(['user']),
      filteredShipments() {
        if (this.shipmentTypes.length == 0) {
          return this.shipments
        } else {
          return this.filterByMode(this.shipments)
        }
      }
    },

    components: {
      Tabs, ShipmentRequests, ShipmentList, Filters
    },

    mounted() {
      this.setBtnText()
      // const path = this.$store.state.path[this.$store.state.env]
      this.getShipments()
    },

    methods: {
      getShipments(query) {
        let path = 'shipments'
        if (query) path += `?query=${query}`
        // path += `?query=Air Ocean Truck`

        this.$ax.get(path)
          .then(res => {
            this.shipments = res.data.shipments
          })
      },

      filterByMode(shipments) {
          return shipments.map(e => {
            return e.filter(elm => {
              return this.shipmentTypes.includes(elm.shipment_type)
            })
          })
      },

      searchShipment(res) {
        this.tabCur = 0
        this.getShipments(res)
      },
      setBtnText() {
        // this.btnText = this.$can('manage', 'Shipment') ? 'Create new shipment' : 'Request new shipment'
        this.btnText = this.$can('manage', 'Shipment') && 'Create new shipment'
      },

      onClickTab(value) {
        this.tabCur = value
      },

      clickTab(e) {
        this.tabCur = e
      },

      selectShipmentMode(e) {
        let m = JSON.parse(JSON.stringify(e))
        this.shipmentTypes = m
      }
    }
  }
</script>

<style scoped>
  .row-border {
    border-bottom-width: 1rem;
  }
</style>
