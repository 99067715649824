<template>
        <div class="flex flex-col justify-between" style="height: 250px">
          <div class="flex items-center">
            <div class="flex justify-center items-center flex-shrink-0 bg-sky-500 rounded-full w-8 h-8 mr-6">
              <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.50004 10.8333L4.33337 13L10.8334 19.5L21.6667 8.66667L19.5 6.5L10.8334 15.1667L6.50004 10.8333Z" fill="white"/>
                </svg>
              </div>
            </div>
            <div>
              <div class="font-bold">Quote Requested</div>
              <div class="text-gray-500 text-xs">Requested On {{shipment.created_at}}</div>
            </div>
          </div>
          <div class="flex items-center">
            <div v-if="shipment.status == 'Pricing' || shipment.status == 'Quote Accepted'" class="flex justify-center items-center flex-shrink-0 bg-sky-500 rounded-full w-8 h-8 mr-6">
              <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.50004 10.8333L4.33337 13L10.8334 19.5L21.6667 8.66667L19.5 6.5L10.8334 15.1667L6.50004 10.8333Z" fill="white"/>
                </svg>
              </div>
            </div>
            <div v-else class="flex justify-center items-center flex-shrink-0 bg-darkgray-500 rounded-full w-8 h-8 mr-6">
              <div>
                <!-- <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.50004 10.8333L4.33337 13L10.8334 19.5L21.6667 8.66667L19.5 6.5L10.8334 15.1667L6.50004 10.8333Z" fill="white"/>
                </svg> -->
              </div>
            </div>
            <div>
              <div class="font-bold">Pricing</div>
              <!-- <div class="text-gray-500 text-xs">Requested On 2021-07-24</div> -->
            </div>
          </div>
          <div class="flex items-center">
            <div  v-if="shipment.status == 'Quote Accepted'" class="flex justify-center items-center flex-shrink-0 bg-sky-500 rounded-full w-8 h-8 mr-6">
              <div>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.50004 10.8333L4.33337 13L10.8334 19.5L21.6667 8.66667L19.5 6.5L10.8334 15.1667L6.50004 10.8333Z" fill="white"/>
                </svg>
              </div>
            </div>
            <div v-else class="flex justify-center items-center flex-shrink-0 bg-darkgray-500 rounded-full w-8 h-8 mr-6">
              <div>
                <!-- <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.50004 10.8333L4.33337 13L10.8334 19.5L21.6667 8.66667L19.5 6.5L10.8334 15.1667L6.50004 10.8333Z" fill="white"/>
                </svg> -->
              </div>
            </div>
            <div>
              <div class="font-bold">Confirmed</div>
              <div v-if="shipment.status == 'Quote Accepted'" class="text-gray-500 text-xs">Waiting for final confirmation by Geo 4 Logistics</div>
            </div>
          </div>
        </div> 
</template>

<script>
export default {
  data() {
    return {

    }
  },

  props: {
    shipment: {type: Object}
  },

  computed: {
    // getConfirmedStyle(e) {
    //   return 'bg-sky-500'
    // }
  },

  methods: {
    getConfirmedStyle() {
      return 'bg-sky-500'
    }
  }

}
</script>

<style scoped>

</style>