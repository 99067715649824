<template>
  <div>
    <v-dropdown placement="left">
        <template v-slot:button>
            <div class="flex items-center justify-evenly rounded-lg bg-white px-6 py-3 mr-4 duration-300 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;">
                <div class="mr-4">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66667 10.6668C4.66667 10.3002 4.96667 10.0002 5.33333 10.0002C5.7 10.0002 6 10.3002 6 10.6668C6 11.0335 5.7 11.3335 5.33333 11.3335C4.96667 11.3335 4.66667 11.0335 4.66667 10.6668ZM8 10.0002H10.6667C11.0333 10.0002 11.3333 10.3002 11.3333 10.6668C11.3333 11.0335 11.0333 11.3335 10.6667 11.3335H8C7.63333 11.3335 7.33333 11.0335 7.33333 10.6668C7.33333 10.3002 7.63333 10.0002 8 10.0002ZM12 13.3335H4C3.63267 13.3335 3.33333 13.0342 3.33333 12.6668V8.66683H12.6667V12.6668C12.6667 13.0342 12.3673 13.3335 12 13.3335M4 4.00016H4.66667V4.66683C4.66667 5.0335 4.96667 5.3335 5.33333 5.3335C5.7 5.3335 6 5.0335 6 4.66683V4.00016H10V4.66683C10 5.0335 10.3 5.3335 10.6667 5.3335C11.0333 5.3335 11.3333 5.0335 11.3333 4.66683V4.00016H12C12.3673 4.00016 12.6667 4.2995 12.6667 4.66683V7.3335H3.33333V4.66683C3.33333 4.2995 3.63267 4.00016 4 4.00016M12 2.66683H11.3333V2.00016C11.3333 1.6335 11.0333 1.3335 10.6667 1.3335C10.3 1.3335 10 1.6335 10 2.00016V2.66683H6V2.00016C6 1.6335 5.7 1.3335 5.33333 1.3335C4.96667 1.3335 4.66667 1.6335 4.66667 2.00016V2.66683H4C2.89733 2.66683 2 3.56416 2 4.66683V12.6668C2 13.7695 2.89733 14.6668 4 14.6668H12C13.1027 14.6668 14 13.7695 14 12.6668V4.66683C14 3.56416 13.1027 2.66683 12 2.66683" fill="#6d6d6d"/>
                    </svg>
                </div>                
                <div class="mr-4 text-darkgray-900">{{btnText}}</div>
                <div class="flex justify-center items-center w-5 h-5 rounded-md bg-gray-300">
                    <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 1C0.5 0.448 0.948 0 1.5 0C2.052 0 2.5 0.448 2.5 1C2.5 1.552 2.052 2 1.5 2C0.948 2 0.5 1.552 0.5 1ZM5 0C4.448 0 4 0.448 4 1C4 1.552 4.448 2 5 2C5.552 2 6 1.552 6 1C6 0.448 5.552 0 5 0ZM8.5 0C7.948 0 7.5 0.448 7.5 1C7.5 1.552 7.948 2 8.5 2C9.052 2 9.5 1.552 9.5 1C9.5 0.448 9.052 0 8.5 0Z" fill="#6d6d6d"/>
                    </svg>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <div class="mt-2 text-left text-gray-500 text-base">{{dropDownText}}</div>
            <div class="my-2">
                <select name="" id="event" :class="style.form.input" class="rounded bg-gray-100 flex w-full">
                    <option value="event" selected disabled>Event</option>
                    <option v-for="event in dateEvent" :key="event" :value="event" class="text-gray-700">{{event}}</option>
                </select>
            </div>
            <div class="my-2">
                <select name="" id="event" :class="style.form.input" class="rounded bg-gray-100 flex w-full" v-model="dateRange">
                    <option value="criteria" selected disabled>Criteria</option>
                    <option v-for="criteria in dateCriteria" :key="criteria" :value="criteria">{{criteria}}</option>
                </select>
            </div>
            <div v-if="dateRange == 'Within'" class="my-2">
                <select name="" id="event" :class="style.form.input" class="rounded bg-gray-100 flex w-full">
                    <option value="time" selected disabled>Time</option>
                    <option v-for="time in dateTime" :key="time" :value="time">{{time}}</option>
                </select>
            </div>
            <div v-else class="my-2">
                <input type="date" name="" id="" placeholder="pick a date" :class="style.form.input" class="rounded bg-gray-100 w-full block">
            </div>

            <hr class="my-4" />

            <div class="flex justify-center mb-2">
                <button type="button" class="w-full" :class="style.btn.fill">Submit</button>
            </div>
        </template>
    </v-dropdown>
  </div>
</template>

<script>
import VDropdown from '../../components/v-dropdown.vue'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            dateRange: "Within",
            dropdown: false,
            showLoader: false,
            dateEvent: [
                'Cargo ready date',
                'Origin Pickup date',
                'Departure date',
                'Arrival Port arrival Date',
                'Arrival Port departure Date',
                'Delivered in full'

            ],

            dateCriteria: [
                'Within',
                'Before',
                'After'
            ],

            dateTime: [
                '24 hours',
                '2 days',
                '3 days',
                '1 week',
                '2 weeks',
                '1 month'
            ],
        }
    },

    props: {
        filters: { type: Object },
        btnText: { type: String },
        dropDownText: { type: String }
    },

    computed: {
      ...mapState(['style'])
    },

    methods: {
    },

    components: {
        VDropdown
    }

}
</script>

<style>

</style>