<template>
  <div>
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th :class="tableStyle.th" style="width: 10%;">REQUEST DATE</th>
          <th :class="tableStyle.th" style="width: 12%;" v-if="$can('manage', 'Shipment')">COMPANY</th>
          <th :class="tableStyle.th" style="width: 10%;">FROM</th>
          <th :class="tableStyle.th" style="width: 10%;">TO</th>
          <th :class="tableStyle.th" style="width: 40%;">ADDITIONAL INFO</th>
          <th :class="tableStyle.th" style="width: 8%;"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="s in shipments">
          <tr class="bg-white hover:bg-blue-100 duration-300 text-sm">
            <td :class="tableStyle.td">{{s.created_at}}</td>
            <td :class="tableStyle.td" v-if="$can('manage', 'Shipment')">
              <div>{{s.client.name}}</div>
              <div class="text-gray-500 text-sm">{{s.client.representative}}</div>
              <div class="text-gray-500 text-sm">{{s.client.email}}</div>
              <div class="text-gray-500 text-sm">{{s.client.phone_number}}</div>
            </td>
            <td :class="tableStyle.td">{{s.loading_port}}</td>
            <td :class="tableStyle.td">{{s.final_destination}}</td>
            <td :class="tableStyle.td">
              <div class="text-sm px-4 py-3 rounded-lg"
                :class="[s.message?'bg-blue-200':'bg-gray-300']">{{s.message||'No message'}}</div>
              <div class="mt-4" v-if="s.documents.length">
                <div class="mb-1 text-gray-500 text-xs font-semibold">DOCUMENTS</div>
                <div v-for="doc in s.documents">
                  <div @click="download($event, doc.url, doc.filename)" class="cursor-pointer hover:text-blue-500 duration-300">{{doc.title}}</div>
                </div>
              </div>
            </td>
            <td :class="tableStyle.td" class="text-right">
              <template v-if="$can('manage', 'Shipment')">
                <button :class="style.btn.ghostSm" class="mb-3 w-full" @click="confirmShipment(s)">Confirm</button>
                <button class="w-full text-xs bg-transparent hover:bg-red-700 text-red-800 font-semibold hover:text-white py-2 px-4 border border-red-700 hover:border-transparent duration-300" @click="deleteRequest(s.id)">Delete</button>
              </template>
              <button v-else :class="style.btn.ghostSm" class="mb-3 w-full" @click="editRequest(s)">Edit</button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="!shipments.length" class="px-4 py-5 text-gray-500 text-base">No requested shipments</div>
    <form-modal v-if="showModal" @toggleModal="toggleModal">
      <shipment-edit :shipmentToEdit="shipmentToEdit" :title="formTitle" btnText="Confirm" :isRequestForm="isRequestForm" />
    </form-modal>
  </div>
</template>

<script>
  import FormModal from '../../components/form_modal'
  import ShipmentEdit from '../edit'
  import axios from 'axios';

  export default {
    data() {
      return {
        showModal: false,
        style: this.$store.state.style,
        tableStyle: {
          th: 'px-4 py-2 text-left text-gray-500 text-xs',
          td: 'px-4 py-6 border-gray-100 row-border align-top'
        },
        shipmentToEdit: null,
        isRequestForm: false,
        formTitle: 'Confirm Shipment'
      }
    },

    components: {
      FormModal, ShipmentEdit
    },

    props: {
      shipments: { type: Array, default: () => [] }
    },

    methods: {
      toggleModal(showModal) {
        this.showModal = showModal
      },

      confirmShipment(shipment) {
        this.showModal = true
        this.shipmentToEdit = shipment
      },

      deleteRequest(id) {
        console.log('deleting shipment')
        let c = confirm("Delete this shipment?")
        if (c) {
          this.$ax.delete(`shipments/${id}`)
            .then(res => {
              console.log(res)
              if (res.status == 200) this.$router.go()
            })
        }
      },

      editRequest(shipment) {
        this.formTitle = 'Edit Shipment Request'
        this.isRequestForm = true
        this.showModal = true
        this.shipmentToEdit = shipment
      },

      download(e, url, filename) {
        console.log('download url',url)
        let path = this.$store.state.path[this.$store.state.env]
        console.log('path', path)
        axios.get(path+url.substring(1), {responseType: 'blob'})
          .then((response) => {
            console.log('download response', response )
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();
        });
      },
    }
  }
</script>
