<template>
    <div class="flex">
        <!-- <div v-if="$can('manage', 'Shipment')" class="flex justify-center items-center bg-white rounded-lg px-6 py-3 mr-4 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;"> -->
        <div class="flex justify-center items-center bg-white rounded-lg px-6 py-3 mr-4 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;">
            <div class="">
                <select class="text-darkgray-900" name="" id="" @change="checkCurrentStatus" v-model="tabCur">
                    <template v-for="c, index in currentStatus">
                        <option v-bind:key="index" :value="index">{{c}}</option>
                    </template>
                </select>
            </div>
        </div>
        <!-- <filter-checkbox :filters="status" btnText="Status" dropDownText="All Statuses" />
        <filter-checkbox :filters="mode" btnText="Mode" dropDownText="Mode of Transporation" />
        <date-filter btnText="Date" dropDownText="Pick An Event" /> -->
        <template v-for="m, index in modes">
            <div v-bind:key="index" class="flex justify-center items-center rounded-lg bg-white mr-2 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px; width: 48px;" :class="selectedModes.includes(m) ? 'bg-sky-500 duration-300' : ''" @click="filterMode($event, m)">
                <svg-filter-ocean v-if="m == 'Ocean'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'"/>
                <svg-filter-truck v-if="m == 'Truck'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'" />
                <svg-filter-air v-if="m == 'Air'" :class="selectedModes.includes(m) ? 'text-white fill-current' : 'text-darkgray-900 fill-current'" />
            </div>
        </template>

    </div>
</template>

<script>
import FilterCheckbox from './filter_checkbox'
import DateFilter from './date_filter'

export default {
    data() {
        return {
            status: [
                "At Origin Location",
                "Origin Stop-Off",
                "Awaiting Arrival at Departure Port",
                "At Departure Port",
                "In Transit to Intermediary Port"
            ],

            currentStatus: [
                'Ongoing',
                'Finished',
            ],

            modes: [
                'Ocean',
                'Truck',
                'Air'
            ],

            dateEvent: [
                'Cargo ready date',
                'Origin Pickup date',
                'Departure date',
                'Arrival Port arrival Date',
                'Arrival Port departure Date',
                'Delivered in full'

            ],

            dateCriteria: [
                'Within',
                'Before',
                'After'
            ],

            dateTime: [
                '24 hours',
                '2 days',
                '3 days',
                '1 week',
                '2 weeks',
                '1 month'
            ],

            tabCur: 0,
            selectedModes: []

        }
    },

    props: {

    },

    components: {
        FilterCheckbox, DateFilter
    },

    methods: {
        checkCurrentStatus(e) {
            this.$emit('current-page', this.tabCur)
        },

        filterMode(e, mode) {
            let idx = this.selectedModes.indexOf(mode)
            if (idx != -1) {
                this.selectedModes.splice(idx, 1)
                this.$emit('select-mode', this.selectedModes)
                return
            }
            this.selectedModes.push(mode)
            this.$emit('select-mode', this.selectedModes)
        }
    }

}
</script>

<style>

</style>