<template>
  <div>
    <div v-if="showUpload" style="width: 40rem;">
      <label :class="style.form.label">UPLOAD DOCUMENTS</label>
      <div class="italic text-sm mb-1">
        Please upload the packing list and the invoice
      </div>
      <input id="uploadButton" ref="file" type="file" style="display:none" @change="uploadFile($event)" multiple>
      <button @click="triggerUpload" style="width: 100px;"
        :class="[isUploading?style.btn.disabledSm:style.btn.ghostSm]"
        :disabled="isUploading">{{isUploading?'Uploading ('+uploadProgress+')':'Upload'}}</button>

      <div class="border-t mt-5" v-if="documents.length">
        <div v-for="doc, index in documents" class="flex justify-between my-2">
          <div class="text-base">{{doc.title}}</div>
          <!-- <div @click="deleteFile($event, doc.id, index)"><svg-trash class="ml-5 w-5 h-5 cursor-pointer duration-300 text-gray-400 fill-current hover:text-blue-500" /></div> -->
        </div>
      </div>

      <button type="button" class="w-full mt-5"
        :class="style.btn.fill" @click="$router.go()">Done</button>
    </div>
    <form v-else style="width: 50rem;" @keyup.enter="saveShipment(shipmentToEdit?'edit':'create')">
      <div v-if="requestOnly">
        <div class="flex justify-center">
          <div class="w-1/2 pr-3">
            <label class="text-right mr-3" :class="style.form.label">LOADING PORT*</label>
            <input v-model="shipment.loading_port" :placeholder="placeholders.port"
              type="text" :class="style.form.input" class="bg-gray-100 w-full">
          </div>
         <!--  <div class="w-1/2 pr-3">
            <label class="text-right mr-3" :class="style.form.label">DISCHARGE PORT*</label>
            <input v-model="shipment.loading_port" :placeholder="placeholders.port"
              type="text" :class="style.form.input" class="bg-gray-100 w-full">
          </div> -->
          <div class="w-1/2 pl-3">
            <label class="text-right mr-3" :class="style.form.label">FINAL DESTINATION*</label>
            <input v-model="shipment.final_destination" :placeholder="placeholders.port"
              type="text" :class="style.form.input" class="bg-gray-100 w-full">
          </div>
        </div>

        <div class="mt-5">
          <div class="flex justify-center">
            <div class="w-1/2 pr-3">
              <label class="text-right mr-3" :class="style.form.label">CARGO WEIGHT (IN KG)</label>
              <input v-model="shipment.weight" :placeholder="placeholders.numeric"
                type="number" :class="style.form.input" class="bg-gray-100 w-full">
            </div>
            <div class="w-1/2 pl-3">
              <label class="text-right mr-3" :class="style.form.label">CARGO VOLUME (IN CBM)</label>
              <input v-model="shipment.cbm" :placeholder="placeholders.numeric"
                type="number" :class="style.form.input" class="bg-gray-100 w-full">
            </div>
          </div>
        </div>

        <div class="mt-5">
          <label class="text-right mr-3" :class="style.form.label">ADDITIONAL INFO*</label>
          <textarea v-model="message" :placeholder="placeholders.message" style="height: 100px;"
            type="text" :class="style.form.input" class="bg-gray-100 w-full"></textarea>
          <div class="italic text-sm" style="white-space: pre-line;">Please let us know the details such as:
            - Type of shipment that is required (ocean, air, truck, etc).
            - Full container or less than a full container
            - Dimensions of the packages (in cm)
          </div>
        </div>
      </div>

      <div v-else class="overflow-y-auto" style="height: 35rem;">
        <div class="grid grid-rows-4 grid-flow-col col-gap-3 row-gap-3 items-end mb-10">
          <label class="text-right" :class="style.form.label">CUSTOMER*</label>
          <label class="text-right" :class="style.form.label">SHIPMENT NO.</label>
          <label class="text-right" :class="style.form.label">INTERNAL REFERENCE</label>
          <label class="text-right" :class="style.form.label">TAGS</label>

          <div v-if="shipmentToEdit&&shipment.client"
            class="pl-3 flex items-center" style="width: 39rem; min-height: 34px;">
            {{shipment.client.code}} - {{shipment.client.name}}
          </div>
          <div v-else class="flex justify-between items-center" style="width: 39rem;">
            <multiselect v-model="selectedCustomer" :options="customers" :custom-label="customerLabel" placeholder="Select one" label="name" track-by="name"></multiselect>
            <div class="flex items-center">
              <p :class="style.form.label" class="mx-3">OR</p>
              <button type="button" :class="style.btn.ghostSm" class="whitespace-no-wrap" @click="createCustomer">Create new customer</button>
            </div>
          </div>

          <div class="pl-3 flex items-center" style="width: 39rem; min-height: 34px;">
            {{shipment.shipment_no}}
          </div>
          
          <!-- <input v-model="shipment.shipment_no" type="text" :class="style.form.input" class="bg-gray-100 w-full h-full" disabled> -->

          <input v-model="shipment.title" type="text" :class="style.form.input" class="bg-gray-100 w-full h-full">
          <input v-model="pos" type="text" :class="style.form.input" class="bg-gray-100 w-full h-full" :placeholder="placeholders.pos">

          <!-- <multiselect v-model="shipment.po" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" :multiple="true" :taggable="true" @tag="addPos"></multiselect> -->
          <!-- <vue-tags-input
            v-model="po"
            :pos="shipment.pos"
            @tags-changed="newTags => shipment.pos = newTags"
          /> -->
          <!-- <input-tag v-model="pos"></input-tag> -->
        </div>

        <div class="grid grid-rows-11 grid-flow-col col-gap-3 row-gap-3 items-end">
          <!-- <label class="text-right" :class="style.form.label">SHIPMENT NO.</label> -->
          <label class="text-right" :class="style.form.label">MBL</label>
          <label class="text-right" :class="style.form.label">HBL</label>
          <label class="text-right" :class="style.form.label">CONTAINER NO.</label>
          <label class="text-right" :class="style.form.label">VESSEL</label>
          <label class="text-right" :class="style.form.label">WEIGHT (IN KG)</label>
          <label class="text-right" :class="style.form.label">CBM</label>
          <label class="text-right" :class="style.form.label">LOADING PORT*</label>
          <label class="text-right" :class="style.form.label">ORIGIN LOCATION*</label>
          <label class="text-right" :class="style.form.label">DISCHARGE PORT*</label>
          <label class="text-right" :class="style.form.label">FINAL DESTINATION*</label>
          <label class="text-right" :class="style.form.label">DEST. LOCATION*</label>

          <!-- <input v-model="shipment.shipment_no"
            type="text" :class="style.form.input" class="bg-gray-100" disabled> -->
          <input v-model="shipment.mbl"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.hbl"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="container_nos" :placeholder="placeholders.pos"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.vessel" :placeholder="placeholders.vessel"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.weight" :placeholder="placeholders.numeric"
            type="number" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.cbm" :placeholder="placeholders.numeric"
            type="number" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.loading_port" :placeholder="placeholders.port"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.origin_location" :placeholder="placeholders.port"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.discharge_port" :placeholder="placeholders.port"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.final_destination" :placeholder="placeholders.port"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.destination_location" :placeholder="placeholders.port"
            type="text" :class="style.form.input" class="bg-gray-100">

          <label class="text-right" :class="style.form.label">INCOTERM</label>
          <label class="text-right" :class="style.form.label">CONTAINER SIZE</label>
          <label class="text-right" :class="style.form.label">SHIPMENT_TYPE</label>
          <!-- <label class="text-right" :class="style.form.label">TOTAL WEIGHT*</label>
          <label class="text-right" :class="style.form.label">TOTAL VOLUME*</label> -->
          <label class="text-right" :class="style.form.label">ETC</label>
          <label class="text-right" :class="style.form.label">ETD</label>
          <label class="text-right" :class="style.form.label">ETA TO PORT</label>
          <label class="text-right" :class="style.form.label">ETA TO FINAL DESTINATION</label>
          <label class="text-right" :class="style.form.label">ATA</label>
          <label class="text-right" :class="style.form.label">FINAL DELIVERY DATE</label>
          <label></label>
          <label></label>

          <div>
            <select v-model="shipment.incoterm" name="mode" id="mode" :class="style.form.input" class="bg-gray-100 w-full">
                <template v-for="i, index in incoterms">
                    <option v-bind:value="i">{{i}}</option>
                </template>
            </select>
          </div>
          <div>
            <select v-model="shipment.container_size" name="mode" id="mode" :class="style.form.input" class="bg-gray-100 w-full">
                <template v-for="c, index in container_size">
                    <option v-bind:value="c">{{c}}</option>
                </template>
            </select>
          </div>
          <div>
            <select v-model="shipment.shipment_type" name="mode" id="mode" :class="style.form.input" class="bg-gray-100 w-full">
                <template v-for="m, index in modes">
                    <option v-bind:value="m">{{m}}</option>
                </template>
            </select>
          </div>
          <!-- <input v-model="shipment.total_weight" placeholder="(kg)"
            type="text" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.total_volume" placeholder="(cbm)"
            type="text" :class="style.form.input" class="bg-gray-100"> -->
          <input v-model="shipment.etc_date"
            type="date" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.etd_date"
            type="date" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.eta_to_port_date"
            type="date" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.eta_to_final_date"
            type="date" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.ata_date"
            type="date" :class="style.form.input" class="bg-gray-100">
          <input v-model="shipment.final_delivery_date"
            type="date" :class="style.form.input" class="bg-gray-100">
        </div>

      </div>

      <div class="mt-5">
        <!-- <button type="button" class="w-full text-gray-500 text-sm duration-300 hover:text-blue-700 outline-none">Back</button> -->
        <button type="button" class="w-full" v-if="shipmentToEdit"
          :class="style.btn.fill" @click="saveShipment('edit')">{{btnText}}</button>
        <button type="button" class="w-full" v-else
          :class="style.btn.fill" @click="saveShipment('create')">Create</button>
        <div v-if="errorMessage" class="text-red-700 text-sm">{{errorMessage}}</div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Multiselect from 'vue-multiselect'
  // import CustomerCreate from '../../customers/create'
  // import VueTagsInput from '@johmun/vue-tags-input';
  // import InputTag from 'vue-input-tag';

  export default {
    data() {
      return {
        // po: '',
        pos: null,
        container_nos: null,
        shipment: {
        },
        validation: ['loading_port', 'final_destination'],
        container_size: ['FCL', 'LCL'],
        incoterms: ['EXW', 'FOB', 'FCA', 'CPT', 'CIP','DAP','DPU','DDP','FAS','CFR','CIF'],
        modes: ['Air', 'Ocean', 'Truck'],
        datesToFormat: ['eta_to_final_date', 'eta_to_port_date', 'etc_date', 'etd_date', 'ata_date', 'final_delivery_date'],
        errorMessage: null,
        customers: [],
        selectedCustomer: null,
        placeholders: {
          pos: "Please separate by comma",
          port: "Enter the city name",
          vessel: "Enter the vessel number",
          numeric: "Enter numbers only",
          message: "Enter your message"
        },
        message: null,
        showUpload: false,
        isUploading: false,
        uploadProgress: null,
        shipmentId: null,
        documents: []
      }
    },

    props: {
      customer: Object,
      requestOnly: Boolean,
      createdCustomer: Object,
      shipmentToEdit: Object,
      btnText: { type: String, default: 'Save' }
    },

    computed: {
      ...mapState(['user', 'style'])
    },

    components: {
      Multiselect //, VueTagsInput //, InputTag
    },

    mounted() {
      if (!this.requestOnly) {
        this.getCustomers()
      } else {
        this.selectedCustomer = {id: this.user.client_id}
      }
      if (this.shipmentToEdit) {
        console.log('editing shipment for client:', this.shipmentToEdit.client.name)
        this.selectedCustomer = this.shipmentToEdit.client
        this.shipment = this.shipmentToEdit
        this.datesToFormat.forEach(d=> {
          if (this.shipment[d]) this.shipment[d] = this.$bc.formatDate(this.shipment[d])
        })
        // if (this.pos) this.pos = this.shipment.pos.join(', ')
        // console.log('shipment', this.shipment)
        this.pos = this.shipment.pos ? this.shipment.pos.join(', ') : null
        this.container_nos = this.shipment.container_nos.join(', ')
        if (this.shipment.message) this.message = this.shipment.message
        if (this.shipment.documents) this.documents = this.shipment.documents
      }
    },

    watch: {
      createdCustomer(newValue, oldValue) {
        if (newValue) this.selectedCustomer = newValue
      }
    },

    methods: {
      createCustomer() {
        this.$emit('createCustomer')
      },

      customerLabel({ code, name }) {
        return `${code} - ${name}`
      },

      saveShipment(action) {
        // this.shipment.is_confirmed = !this.requestOnly
        if (this.$route.name == 'quotes' || this.$route.name == 'shipments') this.shipment.is_confirmed = !this.requestOnly
        console.log('data', this.shipment, this.selectedCustomer, this.message)
        let passValidation = this.validation.every(x => this.shipment[x]) && this.selectedCustomer
        if (this.requestOnly) passValidation = this.message

        let data = {}

        if (passValidation) {
        // if (false) {
          this.shipment.client_id = this.selectedCustomer.id
          if (!this.requestOnly) {
            if (this.pos) {
              this.shipment.pos = this.pos.split(",").map(x=> x.trim())
            }
            if (this.container_nos)  {
              this.shipment.container_nos = this.container_nos.split(",").map(x=> x.trim())
            }

            data = {shipment: this.shipment}
          }  else {
            // data  = this.message ? {shipment: this.shipment, message: {content: this.message}} : {shipment: this.shipment}
            data  = {shipment: this.shipment, message: {content: this.message}}
          }

          console.log('create shipment', this.shipment)

          if (action == 'create') {
            console.log('this.requestOnly', this.requestOnly)
            // let data = this.requestOnly ? {shipment: this.shipment, message: {content: this.message}} : {shipment: this.shipment}
            console.log('data to send', data)
            this.$ax.post('shipments', data)
              .then(res => {
                console.log(res)
                this.processData(res)
              })
          } else if (action == 'edit') {
            this.$ax.put('shipments/'+this.shipment.id, data)
              .then(res => {
                console.log(res)
                this.processData(res)
              })
          }

        } else {
          this.errorMessage = "Please fill out the required fields and try again."
        }
      },

      getCustomers() {
        this.$ax.get('clients')
          .then(res => {
            this.customers = res.data.clients
          })
      },

      processData(res) {
        if (res.status==200) {
          if (this.requestOnly) {
            this.shipmentId = res.data.shipment.id
            this.showUpload = true
          } else if (this.$route.name == 'shipment') {
            this.$router.go()
          } else {
            this.$router.push({ name: 'shipment', params: { slug: res.data.shipment.slug }})
          }
        } else {
          this.errorMessage = "Oops, something went wrong, please try again."
        }
      },

      addPos() {
        // const tag = {
        //   name: newTag,
        //   code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        // }
        // this.options.push(tag)
        this.shipment.pos.push(this.po)
      },

      triggerUpload() {
        document.getElementById("uploadButton").click();
      },

      uploadFile(e) {
        // console.log('ref file', this.$refs.file.files)
        let files = e.target.files
        console.log('files', files)
        // let file = e.target.files[0]
        let formData = new FormData();
        formData.append('shipment_id', this.shipmentId)

        let documents = []

        Array.from(files).forEach((file) => formData.append('files[]', file))
        console.log('documents array', documents)

        // const config = {
        //     onUploadProgress: progressEvent => console.log(progressEvent.loaded)
        // }

        const _this = this

        var config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent) {
            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            _this.isUploading = true
            _this.uploadProgress = percentCompleted
            console.log('uploading', percentCompleted)
            console.log('file upload', progressEvent)
          }
        };

        this.$ax.post('documents', formData, config)
          .then(res => {
            console.log('upload file res', res)
            this.isUploading = false
            this.uploadProgress = null
            this.documents = this.documents.concat(res.data.documents)
          })

      },
      deleteFile(e, id, index) {
        console.log('deleting file id:', id)
        let c = confirm("Delete this file?")
        if (c) {
          // this.$ax.delete(`shipments/${shipment_id}/documents/${id}`)
          this.$ax.delete(`documents/${id}`)
            .then(res => {
              console.log('delete res', res)
              this.documents.splice(index, 1)
              // this.$emit('updateDoc', 'delete doc', index)
            })
          }
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .multiselect__option--highlight {
    background: #3182ce;
  }
  .multiselect__option--highlight::after {
    background: #3182ce;
  }
  .vue-tags-input {
    background: <div id="fff"></div>;
  }

/* multiselect__select */
/* multiselect__tag (container for Select One placeholder) > multiselect__placeholder */
  .multiselect__tags {
/*    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
*/
    /*padding: 2.5px 1.5px;*/
    display: flex;
    padding-top: 0px;
    height: 34px;
    font-size: 16px;
  }

  .multiselect__placeholder {
/*    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;*/
    margin-bottom: 0;
    align-self: center;
  }

  .multiselect__input {
    padding: 2.5px 1.5px;
    font-size: 16px;
    margin-bottom: 0;
/*    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;*/
  }

  .multiselect__single {
    padding: 2.5px 1.5px;
    font-size: 16px;
    margin-bottom: 0;
/*    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border .1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;*/
  }
</style>
