<template>
    <div class="bg-gray-100 p-10 min-h-full">
        <page-title title="Quotes" v-if="$can('manage', 'Shipment')" />
        <page-title title="Quotes" :btnText="btnText" btn="quoteCreate" v-else />
        <div class="flex justify-between mb-10">
            <!-- <filter-tabs :tabs="tabs" /> -->
            <filters @quote-mode="selectShipmentMode($event)" @quote-status="selectStatus($event)" />
            <search-bar placeholder="Search for quote" @search="searchShipment" />
        </div>
        <!-- <quotes-list :quotes="quotes" /> -->
        <quotes-list :quotes="filteredQuotes" @confirm-shipment="confirmShipment($event)" />
        <form-modal v-if="showModal" @toggleModal="toggleModal">
            <shipment-edit :shipmentToEdit="shipmentToEdit" :title="formTitle" btnText="Confirm" :isRequestForm="isRequestForm" />
        </form-modal>
    </div>

</template>

<script>
import { mapState } from 'vuex'
// import Tabs from './components/tabs.vue'
import QuotesList from './components/quotes_list'
import FilterTabs from './components/filter_tabs'
import Filters from './components/filters'
import FormModal from '../components/form_modal'
import ShipmentEdit from '../shipments/edit'

export default {
    data() {
        return {
            btnText: "Create Quote",
            tabs: ['Status', 'Type'],
            quotes: [
            ],
            modes: [
                'Ocean',
                'Truck',
                'Air'
            ],
            selectedModes: [

            ],
            shipmentTypes: [],
            currentStat: 'Quote Requested',
            shipmentToEdit: null,
            isRequestForm: false,
            showModal: false,
            formTitle: 'Confirm Shipment'

        }
    },

    mounted() {
        this.getShipments()
    },

    components: {
        QuotesList, FilterTabs, Filters, FormModal, ShipmentEdit
    },

    computed: {
        ...mapState(['user']),
        filteredQuotes() {
        let q = this.quotes
        q = this.filterByStatus(q)
        if (this.shipmentTypes.length == 0) {
          return q
        } else {
          return this.filterByMode(q)
        }
      }
    },

    methods: {
        toggleModal(showModal) {
            this.showModal = showModal
        },

        getShipments(query) {
            let path = 'shipments'
            if (query) path += `?query=${query}`

            this.$ax.get(path)
            .then(res => {
                this.quotes = [...res.data.shipments[0], ...res.data.shipments[1], ...res.data.shipments[2]]
            })
        },

        confirmShipment(shipment) {
            console.log(shipment)
            this.shipmentToEdit = shipment
            this.showModal = true
        },

        filterByMode(shipments) {
            return shipments.filter(e => {
                return this.shipmentTypes.includes(e.shipment_type)
            })
        },

        filterByStatus(shipments) {
            switch (this.currentStat) {
                case 'All':
                    return shipments
                case 'Pricing':
                    return shipments.filter(e => e.status == this.currentStat && !e.is_confirmed)
                case 'Shipment Confirmed':
                    return shipments.filter(e => e.is_confirmed)
                default:
                    return shipments.filter(e => e.status == this.currentStat && !e.is_confirmed)
            }
            // if (this.currentStat == 'All') {
            //     return shipments
            // } else if (this.currentStat == 'Pricing') {
            //     return shipments.filter(e => e.status == this.currentStat && !e.is_confirmed)
            // } else if (this.currentStat == 'Quote Accepted') {
            //     return shipments.filter(e => e.is_confirmed)
            // }else {
            //     return shipments.filter(e => e.status == this.currentStat)
            // }
        },

        filterMode(e, mode) {
            let idx = this.selectedModes.indexOf(mode)
            if (idx != -1) {
                this.selectedModes.splice(idx, 1)
                return
            }
            this.selectedModes.push(mode)
        },

        searchShipment(res) {
            this.tabCur = 0
            this.getShipments(res)
        },

        selectShipmentMode(e) {
            let m = JSON.parse(JSON.stringify(e))
            this.shipmentTypes = m
        },

        selectStatus(e) {
            e = JSON.parse(JSON.stringify(e))
            this.currentStat = e
        }

    }

}
</script>

<style>

</style>