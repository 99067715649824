// let dev;
// dev = true

import Vue from 'vue/dist/vue.esm';
import App from '../app.vue';
import TurbolinksAdapter from 'vue-turbolinks'; Vue.use(TurbolinksAdapter)
import Vuex from 'vuex'; Vue.use(Vuex)
import VueRouter from 'vue-router/dist/vue-router.js'; Vue.use(VueRouter); const router = new VueRouter({ mode: 'history' })
import VueCanCan from 'vue-cancan';
import ActionCableVue from 'actioncable-vue';
import * as bc from '../helpers/bc.js'; Vue.prototype.$bc = bc

// SVG
import { index } from '../views/svg'
index.forEach(svg => Vue.component(svg.name, svg.component))

// GLOBAL COMPONENTS
import ContactForm from '../views/components/contact_form'
import SideNav from '../views/components/side_nav'; Vue.component('side-nav', SideNav)
import PageTitle from '../views/components/page_title'; Vue.component('page-title', PageTitle)
import SearchBar from '../views/components/search_bar.vue'; Vue.component('search-bar', SearchBar)

import axios from 'axios'

import { style } from '../helpers/style.js'
import JsonExcel from "vue-json-excel";
 
Vue.component("downloadExcel", JsonExcel);

const { host, origin } = location

const store = new Vuex.Store({
  state: {
    // env: dev?'dev':'prod',
    env: process.env.RAILS_ENV,
    // env: 'staging',
    path: {
      development: `${origin}/`,
      production: `${origin}/`,
      staging: `${origin}/`
    },
    wsPath: {
      development: `ws://${host}/cable`,
      production: `wss://${host}/cable`,
      staging: `wss://${host}/cable`
    },
    api: "api/v1/",
    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    user: null,
    logo: null,
    rules: null,
    style: style,
    vueLoaded: false,
    rootLoaded: false,
    contactLoaded: false,
    client: null
  },
  getters: {
    rootPath: state => {
      return state.path[state.env]
    },
    getState: state => {
      return state
    },
    getUser: (state) => {
      return state.user
    }
  },
  mutations: {
    loadData(state, data) {
      state.user = data.user
      state.logo = data.logo
      state.client = data.client
    },
    loadVue(state) {
      console.log('vue loaded', state)
    }
  }
})

Vue.use({
  install (Vue) {
    Vue.prototype.$ax = axios.create({
      baseURL: '/' + store.state.api,
      headers: {"X-CSRF-TOKEN": store.state.csrf}
    })
  }
})

Vue.filter('currency', function (value) {
    return '$ ' + parseFloat(value).toFixed(2);
});

Vue.use(VueCanCan, { rules: window.abilities });
store.rules = window.abilities

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: store.state.wsPath[store.state.env],
  connectImmediately: true,
});

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('root')) {
    if (!window.app) {
      const app = new Vue({
        router: router,
        store: store,
        el: '#root',
        data: {
        },
        components: { App }
      })
    }
  }

  if (document.getElementById('contact')) {
    if (!window.contactForm) {
      const contactForm = new Vue({
        el: '#contact',
        components: { ContactForm }
      })
    }
  }
})
