<template>
  <div>
    <div v-if="showTitle" class="text-xl mb-5 pt-3">{{requestOnly?'Request New Shipment':'New Shipment'}}</div>

    <customer-create v-if="showCustomerCreate" @navBackWithData="getCreatedCustomer" :needNavBack="true" />

    <shipment-form formType="new" v-show="showShipmentCreate"
      :requestOnly="requestOnly" :createdCustomer="createdCustomer" @createCustomer="createCustomer" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import ShipmentForm from './components/form';
  import CustomerCreate from '../customers/create'

  export default {
    data() {
      return {
        title: 'New Shipment',
        showTitle: true,
        showCustomerCreate: false,
        showShipmentCreate: true,
        createdCustomer: null
      }
    },

    props: {
      customer: Object
    },

    computed: {
      ...mapState(['user', 'style']),
      requestOnly() {
        // return this.user.role != 'staff'
        return !this.$can('manage', 'Shipment')
      }
    },

    components: {
      ShipmentForm, CustomerCreate
    },

    methods: {
      showCustomerForm() {
        this.showTitle = false
        this.showCustomerCreate = true
        this.showShipmentCreate = false
      },

      showShipmentForm() {
        this.showTitle = true
        this.showCustomerCreate = false
        this.showShipmentCreate = true
      },

      createCustomer() {
        this.showCustomerForm()
      },

      getCreatedCustomer(customer) {
        // console.log(customer)
        this.createdCustomer = customer
        this.showShipmentForm()
      }
    },
  }
</script>
