<template>
    <div class="bg-gray-100 p-10 min-h-full">
        <page-title title="Staff" :btnText="btnText" btn="staffCreate" />
        <search-bar placeholder="Search for staff" class="mb-10" @search="searchStaff"/>
        <div class="fixed bg-gray-900 opacity-25 inset-0" v-if="showMore" @click="hideOptions"></div>
        <!-- <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        First Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Name
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Phone
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Role
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                        </th>
                    </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="member, index in staff" :key="member.email">
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                        {{ member.first_name }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                        {{ member.last_name }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                        {{ member.email }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                        {{ member.phone_number }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-black-500">
                        {{ member.role }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <div class="relative text-right pr" v-can.manage.User>
                                <button class="border rounded w-6 h-6 duration-300 hover:bg-gray-400 cursor-pointer" @click="showOptions(index)">...</button>
                                <div class="absolute bg-white border top-0" style="right: 1.7rem;" v-if="member.showOptions">
                                    <div @click="editStaff(member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Edit</div>
                                    <div @click="deleteStaff($event, member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Delete</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div> -->

        <table class="table-fixed w-full">
            <thead>
                <tr>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">FIRST NAME</th>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 20%;">LAST NAME</th>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 25%;">EMAIL</th>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 25%;">PHONE</th>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;">ROLE</th>
                <th class="px-4 py-2 text-left text-gray-500 text-xs" style="width: 5%;"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="member, index in staff" class="bg-white hover:bg-blue-100 duration-300 text-sm">
                <td class="px-4 py-6 border-gray-100 row-border align-top">
                    {{member.first_name}}
                </td>
                <td class="px-4 py-6 border-gray-100 row-border align-top">
                    {{member.last_name}}
                </td>
                <td class="px-4 py-6 border-gray-100 row-border align-top">
                    {{member.email}}
                </td>
                <td class="px-4 py-6 border-gray-100 row-border align-top">
                    {{member.phone_number}}
                </td>
                <td class="px-4 py-6 border-gray-100 row-border align-top">
                    {{member.role}}
                </td>
                <td class="pl-4 py-6 pr-8 border-gray-100 row-border align-top">
                    <div class="relative text-right pr" v-can.manage.User>
                    <button class="border rounded w-6 h-6 duration-300 hover:bg-gray-400 cursor-pointer" @click="showOptions(index)">...</button>
                    <div class="absolute bg-white border top-0" style="right: 1.7rem;" v-if="member.showOptions">
                        <div @click="editStaff(member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Edit</div>
                        <div @click="deleteStaff($event, member)" class="border-b cursor-pointer py-2 px-4 bg-white hover:bg-gray-200">Delete</div>
                    </div>
                    </div>
                </td>
                </tr>
            </tbody>
        </table>

        <form-modal v-if="showModal" @toggleModal="toggleModal">
            <staff-edit :staff="staffToEdit" />
        </form-modal>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import FormModal from '../components/form_modal';
import StaffEdit from './edit'
import VueCanCan from 'vue-cancan';

export default {
    data() {
        return {
            btnText: "Create New",
            tabs: ['Status', 'Type'],
            showMore: false,
            showModal: false,
            searchQuery: null,
            staff: []

        }
    },
    components: {
        FormModal, StaffEdit
    },

    mounted() {
        this.btnText = this.$can('manage', 'User') ? "Create New" : ""
        // console.log(this.$can('Read', 'User'), this.$can('read', 'users'))
        if (this.$can('manage', 'users')) {
            this.getStaff()
        } else {
            this.$router.push({ name: 'shipments'})
        }
    },

    computed: {
        ...mapState(['user'])
    },

    methods: {
    searchStaff(res) {
        this.getStaff(res)
    },

    toggleModal(showModal) {
        this.showModal = showModal
    },

    showOptions(index) {
        this.showMore = true
        this.staff.map(c=>c.showOptions = false)
        this.staff[index].showOptions = true
        this.staff = [...this.staff]
      },

    hideOptions() {
        this.showMore = false
        this.staff.forEach(c=>c.showOptions = false)
    },

    getStaff(query) {
        let path = 'managers'
        if (query) path += `?query=${query}`
        this.$ax.get(path)
            .then(res => {
            // console.log(res)
            this.staff = res.data.staff
            })
    },

    editStaff(s) {
        this.hideOptions()
        this.showModal = true
        this.staffToEdit = s
    },

    deleteStaff(e, member) {
        const { id, role } = member

        if (member.role == 'admin') {
            alert('You cant delete an admin')
        } else {
            let c = confirm("Delete this staff member?")
            if (c) {
                this.$ax.delete(`managers/${id}`)
                .then(res=>{
                    this.hideOptions()
                    this.showModal = false
                    this.getStaff();
                })
            }
        }
    }

    }

}
</script>

<style>

</style>