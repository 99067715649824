const SvgLogout = () => import('./logout.vue')
const SvgShip = () => import('./ship.vue')
const SvgCustomer = () => import('./customer.vue')
const SvgBuilding = () => import('./building.vue')
const SvgContainer = () => import('./container.vue')
const SvgMail = () => import('./mail.vue')
const SvgPhone = () => import('./phone.vue')
const SvgDocument = () => import('./document.vue')
const SvgSearch = () => import('./search.vue')
const SvgArrowDown = () => import('./arrow-down.vue')
const SvgDownload = () => import('./download.vue')
const SvgTrash = () => import('./trash.vue')
const SvgCross = () => import('./cross.vue')
const SvgEdit = () => import('./edit.vue')
const SvgTrain = () => import('./train.vue')
const SvgPlane = () => import('./plane.vue')
const SvgTruck = () => import('./truck.vue')
const SvgAirplane = () => import('./airplane.vue')
const SvgQuote = () => import('./navbar/quote.vue')
const SvgStaff = () => import('./navbar/staff.vue')
const SvgAirMode = () => import('./air-mode.vue')
const SvgOceanMode = () => import('./ocean-mode.vue')
const SvgTruckMode = () => import('./truck-mode.vue')
const SvgFcl = () => import('./fcl.vue')
const SvgLcl = () => import('./lcl.vue')
const SvgSmallShip = () => import('./small-ship.vue')
const SvgSmallTruck = () => import('./small-truck.vue')
const SvgSmallAirplane = () => import('./small-airplane.vue')
const SvgFilterOcean = () => import('./filter-boat.vue')
const SvgFilterTruck = () => import('./filter-truck.vue')
const SvgFilterAir = () => import('./filter-air.vue')

const index = [
    {name: 'svg-logout', component: SvgLogout},
    {name: 'svg-ship', component: SvgShip},
    {name: 'svg-customer', component: SvgCustomer},
    {name: 'svg-building', component: SvgBuilding},
    {name: 'svg-container', component: SvgContainer},
    {name: 'svg-mail', component: SvgMail},
    {name: 'svg-phone', component: SvgPhone},
    {name: 'svg-document', component: SvgDocument},
    {name: 'svg-search', component: SvgSearch},
    {name: 'svg-arrow-down', component: SvgArrowDown},
    {name: 'svg-download', component: SvgDownload},
    {name: 'svg-trash', component: SvgTrash},
    {name: 'svg-cross', component: SvgCross},
    {name: 'svg-edit', component: SvgEdit},
    {name: 'svg-train', component: SvgTrain},
    {name: 'svg-plane', component: SvgPlane},
    {name: 'svg-truck', component: SvgTruck},
    {name: 'svg-airplane', component: SvgAirplane},
    {name: 'svg-quote', component: SvgQuote},
    {name: 'svg-staff', component: SvgStaff},
    {name: 'svg-air-mode', component: SvgAirMode},
    {name: 'svg-ocean-mode', component: SvgOceanMode},
    {name: 'svg-truck-mode', component: SvgTruckMode},
    {name: 'svg-fcl', component: SvgFcl},
    {name: 'svg-lcl', component: SvgLcl},
    {name: 'svg-small-ship', component: SvgSmallShip},
    {name: 'svg-small-truck', component: SvgSmallTruck},
    {name: 'svg-small-airplane', component: SvgSmallAirplane},
    {name: 'svg-filter-truck', component: SvgFilterTruck},
    {name: 'svg-filter-air', component: SvgFilterAir},
    {name: 'svg-filter-ocean', component: SvgFilterOcean},
  ]

export {
  index
}
