<template>
    <div id="quote">
      <show-loader v-if="showLoader" />
        <div class="flex items-center justify-between">
            <div class="text-black text-lg font-semibold mb-4">Quote Details - {{quote.ref}}</div>
            <!-- <button :class="style.btn.fillSm" @click="generateQuote">Download PDF</button> -->
            <button :class="style.btn.fillSm" @click="generateQuote(quote)">Download PDF</button>
        </div>
      <div id="general-info" class="mb-4 border-b pb-6">
        <div class="flex justify-between" style="width: 100%;">
          <div style="width: 50%;">
            <div class="mb-2">Shipment Information</div>
            <table id="shipment-information">
              <tr>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Mode</td>
                <td class="w-2/3 pr-4 py-2">{{quote.mode}}</td>
              </tr>
              <tr>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Carrier</td>
                <td class="w-2/3 pr-4 py-2">{{quote.carrier_name}}</td>
              </tr>
            </table>
          </div>
          <div class="border-l pl-6" style="width: 50%;">
            <div class="mb-2">Route</div>
            <table id="shipment-route">
              <tr>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Origin</td>
                <td class="w-1/3 px-4 py-2">{{quote.origin}}</td>
                <td class="text-gray-500 text-xs pr-4 py-2">Days</td>
              </tr>
              <tr>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Port To Port</td>
                <td class="w-1/3 px-4 py-2">{{quote.port_to_port}}</td>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Days</td>
              </tr>
              <tr>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Destination</td>
                <td class="w-1/3 px-4 py-2">{{quote.destination}}</td>
                <td class="text-gray-500 text-xs w-1/3 pr-4 py-2">Days</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="mb-4 mr-2 w-2/3">
          <div class="text-black font-bold mb-4">Quote</div>
          <div class="overflow-y-auto" style="height: 15rem">
              <table id="quote-details" class="table-fixed w-full">
                  <thead>
                      <tr>
                      <th class="pr-4 py-2 text-left text-gray-500 text-xs" style="width: 35%;">Description</th>
                      <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Rate</th>
                      <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Quantity</th>
                      <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 15%;">Unit</th>
                      <th class="px-4 py-2 text-right text-gray-500 text-xs" style="width: 20%;">Price</th>
                      </tr>
                  </thead>
                  <tbody>
                      <template v-for="s in quote.price_table">
                          <tr>
                              <td class="pr-4">
                                  {{s.item}}
                              </td>
                              <td class="pr-4 text-right">
                                  {{s.price | currency}}
                              </td>
                              <td class="pr-4 text-right">
                                  {{s.quantity}}
                              </td>
                              <td class="text-right">
                                  {{s.unit}}
                              </td>
                              <td class="pr-4">
                                  <div class="calculated-cost text-right">{{(s.quantity * s.price) || 0 | currency}}</div>
                              </td>
                          </tr>
                      </template>
                      <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td><div class="font-bold text-right pr-4">Total</div></td>
                          <td><div class="font-bold text-right pr-4">{{quote.total | currency}}</div></td>
                      </tr>
                  </tbody>
              </table>
          </div>
        </div>
        <div class="mb-4 w-1/3 border-l pl-6">
          <div class="text-black font-bold mb-4">Route</div>
          <template v-for="route, index in quote.routes">
            <div class="mb-4 flex items-center">
              <div class="flex justify-center items-center mr-4 bg-blue-100 rounded-full" style="width: 32px; height: 32px">
                <svg t="1628245798736" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2013" width="32" height="32"><path d="M664.592 446.928c-0.576-83.824-68.64-151.6-152.592-151.6-84.32 0-152.672 68.352-152.672 152.672 0 26.592 6.816 51.568 18.768 73.328 12.496 28.576 27.248 54.112 27.248 54.112l104.336 173.872 105.344-173.872c0 0 16.544-25.408 30.688-53.808 3.056-5.568 5.712-11.376 8.096-17.328 6.72-15.584 11.6-30.736 11.6-41.888C665.408 457.168 665.12 452.016 664.592 446.928zM512.128 520.4c-40.112 0-72.624-32.512-72.624-72.624s32.512-72.624 72.624-72.624 72.624 32.512 72.624 72.624S552.24 520.4 512.128 520.4z" p-id="2014" fill="#5287B8"></path></svg>              </div>
              <div>
                <div class="font-bold text-sm">{{route.category}}</div>
                <div class="text-sm">{{route.location}}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      
    </div>
</template>

<script>
  import ShowLoader from '../../components/show_loader'
export default {
    data() {
        return {
            style: this.$store.state.style,
            showLoader: false
        }
    },

    components: {
      ShowLoader    },

    props: {
      quote: { type: Object }
    },

    methods: {
      generateQuote(quote) {
        this.showLoader = true
        this.$ax.get(`quotes/${quote.slug}`, { responseType: 'blob' })
        .then(res => res.data)
        .then(blob => {
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = url
          a.download = `${this.quote.ref}`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
          this.showLoader = false
        })

      }
    }

}
</script>

<style>

</style>