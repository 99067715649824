<template>
  <div>
    <div class="text-xl mb-5 pt-3">New Customer</div>
    <customer-form :needNavBack="needNavBack" @navBackWithData="navBackWithData" />
  </div>
</template>

<script>
  import CustomerForm from './components/form';

  export default {
    data() {
      return {
      }
    },

    props: {
      needNavBack: { type: Boolean, default: false }
    },

    components: {
      CustomerForm
    },

    methods: {
      navBackWithData(data) {
        this.$emit('navBackWithData', data)
      }
    }
  }
</script>
