<template>
  <div>
    <v-dropdown placement="left">
        <template v-slot:button>
            <div class="flex items-center justify-evenly rounded-lg bg-white px-6 py-3 mr-4 duration-300 cursor-pointer" style="box-shadow: 0 3px 15px 0px rgb(0 0 0 / 5%); height: 48px;">
                <div class="mr-4">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0002 11.8921L8.76286 8.18079L12.4749 4.46812L10.0002 11.8921ZM11.532 3.52544L7.82 7.23744L4.108 6.0001L11.532 3.52544ZM14.66 1.96545C14.6567 1.90345 14.6454 1.84345 14.6247 1.78545C14.618 1.76478 14.6107 1.74478 14.6014 1.72478C14.5694 1.65345 14.5287 1.58612 14.4714 1.52878C14.414 1.47145 14.3467 1.43078 14.2747 1.39878C14.2554 1.38945 14.236 1.38212 14.2154 1.37545C14.156 1.35478 14.0954 1.34278 14.032 1.33945C14.0174 1.33878 14.004 1.33545 13.9887 1.33545C13.922 1.33678 13.8547 1.34612 13.7894 1.36745L1.78937 5.36745C1.51671 5.45878 1.33337 5.71278 1.33337 6.00012C1.33337 6.28745 1.51671 6.54212 1.78937 6.63278L7.47271 8.52745L9.36804 14.2114C9.45871 14.4834 9.71271 14.6668 10 14.6668C10.2874 14.6668 10.5414 14.4834 10.632 14.2114L14.632 2.21145C14.6534 2.14545 14.6634 2.07878 14.664 2.01212C14.6647 1.99612 14.6607 1.98145 14.66 1.96545V1.96545Z" fill="#6d6d6d"/>
                    </svg>
                </div>
                <div class="mr-4 text-darkgray-900">{{btnText}}</div>
                <div class="flex justify-center items-center w-5 h-5 rounded-md bg-gray-300">
                    <div class="font-semibold text-xs text-darkgray-900">{{checkedFilters.length}}</div>
                </div>
            </div>
        </template>
        <template v-slot:content>
            <div class="mt-2 text-left text-gray-500 text-base">{{dropDownText}}</div>
            <ul>
                <li class="mt-3" v-for="(filter, index) in filteredList">
                    <div class="flex items-center">
                        <input :id="index" v-model="checkedFilters" :value="filter" class="flex-shrink-0 form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md focus:outline-none" type="checkbox">
                        <label class="ml-2 text-gray-700" :for="index">{{filter}}</label>
                    </div>
                </li>
            </ul>
            <div>
            <hr class="my-4" />
            <div v-model="selectAll" class="text-right mb-4">
                <div class="flex items-center justify-end">
                    <label for="selectAll">{{selectAllText}}</label>
                    <input class="flex-shrink-0 ml-2 h-5 w-5 form-tick appearance-none border border-gray-300 rounded-md focus:outline-none" type="checkbox" id="selectAll" @click="selectAll" v-model="allSelected"></div>
                </div>
                <!-- <div>{{checkedFilters.length}} SELECTED</div> -->
            </div>
        </template>
    </v-dropdown>
  </div>
</template>

<script>
import VDropdown from '../../components/v-dropdown.vue'

export default {
    data() {
        return {
            // filters: [],
            search: "",
            checkedFilters: [],
            allSelected: false,
            selectAllText: 'Select All',
            activeTrigger: false,
            dropdown: false,
            showLoader: false
        }
    },

    props: {
        filters: { type: Array },
        btnText: { type: String },
        dropDownText: { type: String }
    },

    computed: {
        filteredList() {
            return this.filters.filter(item => {
                return item.toLowerCase().includes(this.search.toLowerCase());
            })
        },
    },

    methods: {
        selectAll: function() {
            this.allSelected = !this.allSelected
            this.checkedFilters = [];
            this.selectAllText = this.selectAllText == "Select All" ? 'Clear All' : 'Select All';
            if (this.allSelected) { 
                console.log('allSelected is true')
                for (const filter in this.filters) {
                this.checkedFilters.push(this.filters[filter].toString());
                }
            }
            console.log(this.checkedFilters)
        },
        showDropDown() {

        }
    },

    components: {
        VDropdown
    }

}
</script>

<style>
    input[type=checkbox]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
        background-color: #5287B8;
        border: transparent;
    }

</style>