<template>
  <div>
    <div class="flex justify-start">
      <div v-for="tab, index in tabs"
        class="px-6 py-3 mr-6 duration-300 cursor-pointer rounded-full"
        :class="tabCur==index?style.tabActive:style.tab"
        @click="changeTab(index)">{{tab}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabs: [ 'Ongoing', 'Finished', 'Requested' ],
        // tabCur: 0,
        style: {
          tab: 'text-gray-400 bg-transparent hover:bg-gray-300 hover:text-sky-500',
          tabActive: 'text-white bg-sky-500'
        }
      }
    },

    props: {
      tabCur: Number
    },

    methods: {
      changeTab(tab) {
        if (this.tabCur != tab) {
          // this.tabCur = tab
          this.$emit('clicked', tab)
        }
      }
    }
  }
</script>
