<template>
    <div class="bg-gray-100 min-h-full">
        <show-loader v-if="showLoader" />
        <div class="px-10 pt-10">
            <page-title title="New Quote" style="margin-bottom: 0" hideBorder />
        </div>
        <div class="md:flex border-t">
            <div class="bg-white md:w-2/3 p-10 overflow-y-scroll h-screen">
                <general @general-data="getData($event, 'general')" />
                <hr />
                <destination @destination="getData($event, 'destination')" />
            </div>
            <quote-details @quote-details="getData($event, 'quoteDetails')" class="bg-white p-10 md:w-1/3 overflow-y-scroll h-screen" />
        </div>
        <div class="flex justify-end items-center fixed inset-x-0 bottom-0 bg-gray-100 px-10 py-4">
            <div v-if="showRequiredAlert" class="text-red-500 mr-2">Please fill out all the required information (*)</div>
            <button class="mr-2" :class="style.btn.ghostSm">Cancel</button>
            <button :class="style.btn.fillSm" @click="onSubmit">Request a Quote</button>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import QuoteDetails from './components/quote_details'
import General from './components/general'
import Destination from './components/destination'
import ShowLoader from '../components/show_loader'


export default {
    data() {
        return {
            // style: this.$store.state.style,
            formData: {

            },
            general: {},
            quoteDetails: {},
            destination: {},
            shipmentId: null,
            isUploading: false,
            percentCompleted: null,
            fileData: null,
            shipment: null,
            showLoader: false,
            showRequiredAlert: false,
            message: '',
            requiredFields: ["shipment_type", "container_size", "loading_port", "final_destination", "origin_location", "destination_location"]


        }
    },

    computed: {
        ...mapState(['user', 'style'])
    },

    components: {
        QuoteDetails, General, Destination, ShowLoader
    },

    methods: {
        getData(e, componentName) {
            if (e.files) this.fileData = e.files
            // console.log(e)
            if (e.message) this.message = e.message
            let obj = JSON.parse(JSON.stringify(e))
            let compName = JSON.parse(JSON.stringify(componentName))
            this.$data[compName] = obj
        },
        
        onSubmit() {
            let data = {...this.general, ...this.quoteDetails, ...this.destination, ...this.message}
            if (this.passValidation(data, this.requiredFields)) {
                this.showLoader = true
                // this.$ax.post('requests', data)
                this.$ax.post('requests', {shipment: data, message: {content: this.message}})
                  .then(res => {
                    this.processData(res)
                })
            } else {
                // alert('You are missing some stuff')
                this.showRequiredAlert = true
            }
        },

        processData(res) {
            if (res.status==200) {
                this.shipmentId = res.data.shipment.id
                this.shipment = res.data.shipment
                this.showUpload = true
                if (this.fileData) {
                    this.uploadFiles()
                    return
                }
                this.showLoader = false
                this.$router.push({ name: 'shipment', params: { slug: res.data.shipment.slug }})
            } else {
            this.errorMessage = "Oops, something went wrong, please try again."
            }
        },

        passValidation(toBeValidatedObj, requiredFields) {
            // return requiredFields.every(k => Object.keys(toBeValidatedObj).includes(k))
            return requiredFields.every(k => toBeValidatedObj[k])
        },

        uploadFiles() {
            let formData = this.fileData
            formData.append('shipment_id', this.shipmentId)

            const _this = this

            var config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            
            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                _this.isUploading = true
                _this.uploadProgress = percentCompleted
                console.log('uploading', percentCompleted)
                console.log('file upload', progressEvent)
            }
            };

            this.$ax.post('documents', formData, config)
            .then(res => {
                // console.log('upload file res', res)
                this.isUploading = false
                this.uploadProgress = null
                this.showLoader = false
                this.$router.push({ name: 'shipment', params: { slug: this.shipment.slug }})
                
            })
        }
    }

}
</script>


<style>

</style>